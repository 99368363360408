import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';

const Infrastructure_senior_wing = () => {
    // React state to track the active tab
    const [activeTab, setActiveTab] = useState('isw1');

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Infrastructure Senior Wing" breadcrumbName="Infrastructure Senior Wing" />

            <div className="container toppad-30">
                <div className="row"> 
                        <div className="col-md-3 leftmenu">
                            <div className="leftmenubg">
                                <p className="menuhead">Important Links</p>
                            </div>
                            <div id="events">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className={activeTab === 'isw1' ? 'active' : ''}>
                                        <Link to="#isw1" onClick={() => setActiveTab('isw1')}>Cafeteria</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw2' ? 'active' : ''}>
                                        <Link to="#isw2" onClick={() => setActiveTab('isw2')}>Book Shop</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw4' ? 'active' : ''}>
                                        <Link to="#isw4" onClick={() => setActiveTab('isw4')}>Physics Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw5' ? 'active' : ''}>
                                        <Link to="#isw5" onClick={() => setActiveTab('isw5')}>Chemistry Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw6' ? 'active' : ''}>
                                        <Link to="#isw6" onClick={() => setActiveTab('isw6')}>Biology Lab</Link>
                                    </li>
                                    <li role="presentation">
                                        <Link to="/library">Library</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw7' ? 'active' : ''}>
                                        <Link to="#isw7" onClick={() => setActiveTab('isw7')}>Maths Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw8' ? 'active' : ''}>
                                        <Link to="#isw8" onClick={() => setActiveTab('isw8')}>Home Science Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw9' ? 'active' : ''}>
                                        <Link to="#isw9" onClick={() => setActiveTab('isw9')}>Computer Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw10' ? 'active' : ''}>
                                        <Link to="#isw10" onClick={() => setActiveTab('isw10')}>Junior Science Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw11' ? 'active' : ''}>
                                        <Link to="#isw11" onClick={() => setActiveTab('isw11')}>Electronic Gadgets Lab</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw12' ? 'active' : ''}>
                                        <Link to="#isw12" onClick={() => setActiveTab('isw12')}>Art &amp; Craft</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw13' ? 'active' : ''}>
                                        <Link to="#isw13" onClick={() => setActiveTab('isw13')}>Music</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw14' ? 'active' : ''}>
                                        <Link to="#isw14" onClick={() => setActiveTab('isw14')}>Dance</Link>
                                    </li>
                                    <li role="presentation" className={activeTab === 'isw15' ? 'active' : ''}>
                                        <Link to="#isw15" onClick={() => setActiveTab('isw15')}>Sports</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="tab-content">
                               
                                {activeTab === 'isw1' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw1">
                                        <h3 className="sub_head">Cafeteria</h3>
                                        <p>A school cafetaria is located inside the school premises which sells items authorised by the
                                            school. No Junk food is sold in the cafetaria. Student must buy the eatables only from school cafeteria. </p>
                                        <div className="pad-10"></div>
                                        <p>Students can visit the cafeteria during the recesses only and are not permitted to leave their
                                            classes for going to cafeteria. For any suggestions/complaints regarding the shop, please
                                            communicate with Admin Officer.</p>
                                    </div>
                                )}

                                {/* Book Shop Tab */}
                                {activeTab === 'isw2' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw2">
                                        <h3 className="sub_head">Book Shop</h3>
                                        <p>School Book Shop is located inside the school premises. The prescribed set of books are
                                            available in the school book shop along with all requisite stationery items such as note
                                            books, files, folders maps etc. Purchase of books from the school authorised shop/vendor
                                            helps in maintaining uniformity among students. The timings of the book shop are from 0830
                                            hrs to 1400 hrs on all working days (with students) of school. </p>
                                        <div className="pad-10"></div>
                                        <p>Students are prohibited to do any purchases during their study periods. However, they are
                                            permitted to visit bookshop during recess and after school hours. For any suggestions/complaints
                                            regarding the shop, please communicate with Admin Officer.</p>
                                    </div>
                                )}

                                {/* Physics Lab Tab */}
                                {activeTab === 'isw4' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw4">
                                        <h3 className="sub_head">Physics Lab</h3>
                                        <p>Performing experiments in Physics is part of the curriculum from class 9 onwards.
                                            Students enjoy freedom with apparatus in the Physics lab and handle various types of
                                            instruments under the able guidance of teachers and supervision of laboratory
                                            assistant. They can work on investigatory projects with the help of teachers. Physics lab
                                            is fully equipped and ever expanding to demonstrate new experiments that are added
                                            from time to time. </p>
                                        <div className="pad-10"></div>
                                        <p>In the year 2015 -16, classes in Robotronics were introduced and 15 students
                                            participated in it. This year the number of students is 28 out of which 4 students are
                                            attending the second level, while others are attending first level. Last year one of our
                                            teams comprising of 4 students reached national level of competition. </p>
                                        <p className="height-40"></p>
                                        <div className="col-md-6 col-sm-6 col-xs-6 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Physics-Lab.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-md-6 col-sm-6 col-xs-6 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Physics-Lab2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                    </div>
                                )}

                                {/* Chemistry Lab Tab */}
                                {activeTab === 'isw5' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw5">
                                        <h3 className="sub_head">Chemistry Lab</h3>
                                        <h4 className="text-line">KALAIDOSCOPE</h4>
                                        <p className="text-line">Chemistry Exhibition:- 23rd July 2016</p>
                                        <div className="pad-10"></div>
                                        <p>The chemistry exhibition was held on 23rd July 2016, Saturday, during the first
                                            PTM of the session, allowing the parents to view the exhibits put up by the
                                            students of classes 6th to 10th. The students of class 6th made charts to explain
                                            different changes in nature. The students of classes 7th and 8th had a ‘Best out
                                            of waste based project.’ The 7th graders made dolls representing the culture
                                            and color of different states of India, only using waste fabrics and yarns, while
                                            those of class 8th made interesting day-to-day usage items using waste material
                                            like lamp shades, table cloths, decorative items etc. </p>
                                        <div className="pad-10"></div>
                                        Keeping in pace with the change in the difficulty level of studies from class 8th to 9th, the students of
                                        class 9th had a more advanced topic and had made informative models to show
                                        the movement of molecules in different states of matter. The students of class
                                        10th mean while made a project based on the first chapter of their syllabus,
                                        chemical reactions, that will be the basis of their further education should they
                                        choose to go ahead and take the science stream in their future years. All the
                                        parents who visited the chemistry exhibition appreciated the efforts of
                                        children and praised them for their excellent work and talent.
                                        <p></p>
                                        <p className="height-40"></p>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Chemistry.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Chemistry1.jpg" className="img-responsive center-block " alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Chemistry2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <br />
                                        </div>
                                        <div className="pad-10"></div>
                                        <p className="text-line">THE CHEMPOSIUM<br />
                                            AFGJI CHEMISTRY SYMPOSIUM:- 2016-2017</p>
                                        <div className="pad-10"></div>
                                        <p>The confluence, AFGJI’s annual inter-school jamboree, was held from the 28th to
                                            the 29th of July 2016. Students from schools all over Delhi appeared to participate
                                            in the many competitions, declamations and debates held over two days. Among
                                            these, in only its second year running, the ‘Chemposium’, the Chemistry
                                            symposium, was an all-out success. The initial entry list, which was to close a
                                            week before the competition, had only twenty five entries, but by the day of the
                                            competition, more than thirty schools were present to take part. </p>
                                        <div className="pad-10"></div>
                                        The Chemposium was a competition where each team of two students presented
                                        their views on the modern applications of Chemistry in the form of a PowerPoint
                                        presentation on topics ranging from the use of Chemistry in festivals to that in the
                                        modern fashion industry. But the hottest topic of discussion, by far was ‘Chemical
                                        Warfare-Chemistry on the battlefield.’
                                        <div className="pad-10"></div>
                                        The students also had rather unusual topics like the use of Chemistry in the
                                        automobile, aerospace and marine industry. Another highlight of the event were
                                        our judges, Mr. Aadarsh Mehndiratta,  Ms. Sukanya Rai and Ms. Priyanka Shukla, all
                                        of whom are alumni of AFGJI, passing out from the school in 2012 with excellent
                                        grades in Chemistry, and all of them are presently beginning their careers in major
                                        companies and firms. The students took up the challenge and put up rather
                                        interesting points in their presentations, giving everyone the opportunity of seeing
                                        the upcoming advances across the modern world with the aid of Chemistry.
                                        <div className="pad-10"></div>
                                        The event also had a lot of support from the school, with students from all classes
                                        volunteering to be part of the organizing committee of the event. The end of the
                                        competition also saw each judge, barely out of school a few years back, say a few
                                        words of encouragement and motivation to the gathering of classes XI and XII
                                        science students from across Delhi. All in all, the Chemposium was a huge
                                        success, with students giving their best for the first position, and with a promising
                                        scope in the future, as these young minds showed rather ingenious ideas when it
                                        came to voicing their opinions in the form of a PowerPoint presentation.
                                        <div className="pad-10"></div>
                                        In the end, everyone had to agree that in the coming years, the competition had a lot of
                                        scope for bringing out the talent in young minds and allowing them to express their
                                        opinions on the changes that can be brought with the help of modern Chemistry to
                                        make today’s world a better place.
                                        <p></p>
                                    </div>
                                )}

                                {/* Biology Lab Tab */}
                                {activeTab === 'isw6' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw6">
                                        <h3 className="sub_head">Biology Lab</h3>
                                        <p>Biology Lab (department) is as old as our school that is it was set up in 1986 and is being
                                            constantly upgraded every year according to the needs of the students. It is well equipped with
                                            microscopes, specimens, projectors, various instruments and other materials needed by the
                                            students. </p>
                                        <div className="pad-10"></div>
                                        It is also a place where we show case our home-works of students and other objects
                                        during science exhibition. Recently it was renovated according to the present conditions.
                                        <p></p>
                                        <p className="height-40"></p>
                                        <div className="col-xs-6 col-sm-6 col-md-6 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Biology.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-6 col-sm-6 col-md-6 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Biology1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                    </div>
                                )}
                                {activeTab === 'isw7' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw7">
                                        <h3 className="sub_head">Maths Lab</h3>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Maths-SeniorWings/DSC_0018.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Maths-SeniorWings/DSC_0019.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Maths-SeniorWings/DSC_0021.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Maths-SeniorWings/DSC_0022.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Maths-SeniorWings/DSC_0023.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'isw8' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw8">
                                        <h3 className="sub_head">Home Science Lab</h3>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08957.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08958.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08959.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08960.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100 "><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08962.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08964.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08966.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad> </div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08968.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Home-science-lab/DSC08969.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'isw9' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw9">
                                        <h3 className="sub_head">Computer Lab</h3>
                                        <p>The school boasts of beautifully designed senior computer science lab which caters to the students of class XI and XII opting for Computer Science. The lab has 30 computers having i3 processors and has C++ and MySql installed on each machine. In addition to this the lab also has an internet connection,two laser printers and a scanner.  All computers are on Local Area Network with a centralized UPS having half an hour backup facility.</p>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/computer-lab/1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/computer-lab/2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/computer-lab/3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/computer-lab/4.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/computer-lab/5.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'isw10' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw10">
                                        <h3 className="sub_head">Junior Science Lab </h3>
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/cm.png" className="img-responsive" alt="AFGJI" /></LazyLoad>
                                        <p className="height-40"></p>
                                    </div>
                                )}
                                {activeTab === 'isw11' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw11">
                                        <h3 className="sub_head">Electronic Gadgets Lab </h3>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/4.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/5.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/6.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/7.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/8.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/electronic/9.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'isw12' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw12">
                                        <h3 className="sub_head">Art &amp; Craft </h3>
                                        <p>Since its inception, the Air Force Golden Jubilee Institute Art &amp; Craft
                                            Department has been the hub of cultural and artistic activities in the school.
                                            Over the years, it has been instrumental in carving and moulding the
                                            aesthetic qualities of the students. The Art and Craft Dept. boost of students
                                            who have either established themselves in the field of art or are pursuing art
                                            from some of the premier institutions of our country like NID, Ahmadabad
                                            and NIFT. </p>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100 "><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <p>A spacious and well maintained Art Room provides a conducive atmosphere
                                            to the student artists. The Art Dept. has a rich collection of Art books that
                                            broaden the horizon of knowledge of the students. Art Exhibitions,
                                            competitions, visits to Art Galleries , Museums and Workshops opens up the
                                            mind and soul of the budding artists towards the World of Art. Apart from
                                            this, students are made aware of the latest art trends and New Media Art
                                            through video projections. The Dept. is constantly striving to inculcate
                                            aesthetic values in the young artists.</p>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/4.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/5.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/6.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad> </div>
                                        </div>
                                        <div className="pad-20"></div>
                                        <h3 className="sub_head">Bamboo Cane Craft Workshop</h3>
                                        <p>The students of Fine Arts and Home Science had a unique experience to know
                                            about a form of craft that has its own aesthetic and anthropological significance.
                                            A Bamboo Cane Craft Workshop was organized in the Art Room on 25th, 26th , and
                                            27th April 2016. National awardee Mr. Vivekananda Bagchi was the resource
                                            person for the workshop. The students who were novice in this form of craft
                                            came to know not only about the qualities of bamboo cane but also about the
                                            nuances of weaving. </p>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/7.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/9.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/art/8.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        In the process, they made some decorative baskets, bangles and other ornaments.
                                        More than 75 students participated in this workshop. The workshop culminated
                                        with the Closing Ceremony where Mr. Vivekananda Bagchi was felicitated by
                                        the school Principal Mrs Poonam S Rampal in the presence of the Vice Principal
                                        Mrs Rashmi Soota and other distinguished guests. Padmashree
                                        awardee Sri. Jai Prakash was the Guest of Honor on this occasion.
                                        <p></p>
                                    </div>
                                )}
                                {activeTab === 'isw13' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw13">
                                        <h3 className="sub_head">Music</h3>
                                        <p>The Music Room of the Senior Wing building AFGJI is that creative and fun zone where students let themselves loose from their academic pressures and enjoy learning, practicing and making music together. The room is equipped with well-stocked professional grade instruments to help the students learn and enjoy their Music. Unlike many other academic institutions, the students get to lay their hands on the real instruments to support their learning and growth. We keep increasing our instruments inventory such that every child gets to hold and play an instrument during the classes. The zeal and enthusiasm of the Management and the Music Teachers in providing the best of Music infrastructure in the school is a clear reflection of the school's ideology to keep creative arts in tandem with the academic flow.</p>
                                        <p>This space, situated in the North West top most corner of the Institution building is surely one of the Top stress buster zones in the school! </p>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <div className="pad-10"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/4.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/5.jpg" className="img-responsive center-block " alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/6.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'isw14' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw14">
                                        <h3 className="sub_head">Dance</h3>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/dance/1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/dance/2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/dance/3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                        <p className="height-40"></p>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/dance/4.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/dance/5.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'isw15' && (
                                    <div role="tabpanel" className="tab-pane active" id="isw15">
                                        <h3 className="sub_head"></h3>
                                        <div className="pad-10"></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports4.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports5.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports6.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports7.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports8.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100 mt-2"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/sports9.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                    </div>
                                )}

                               
                            </div>
                        </div>
                    </div>
                </div>
            
            <Footer />
        </>
    );
}

export default Infrastructure_senior_wing;
