
import Header from "../Component/Header";
import Footer from "../Component/Footer";  
import Breadcrumb from "../Component/Breadcrumb";  
const VideoGallery = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Video Gallery" breadcrumbName="Video Gallery" />
          
            <div className="container body_height">
  <div className="row"> 
  <div className="col-md-6">
   <p className="text-center"><strong>SUMMER FIESTA - Special Wing AFGJI 18th May 2023</strong></p>
<iframe width="100%" height="305" src="https://www.youtube.com/embed/qn9GVCpgHRQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </div>
  <div className="col-md-6">
  	  <p className="text-center"><strong>Chairmen's Message for all Teachers</strong></p>
	<div id="video-bg">
        <video controls> 
            <source type="video/mp4" src="https://webapi.entab.info/api/image/AFGJI/public/Images/VID-20200905-WA0000.mp4" media="(orientation:landscape)" />
        </video>
    </div>
  </div>

	</div>
</div>
                <Footer />
        </>
    )
}

export default VideoGallery