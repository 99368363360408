import React from 'react'; 
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
    <div className="container-fluid footer_sec">
  <div className="row">
    <div className="container">
      <div className="col-xs-6 col-sm-12 col-md-5 footer_grid">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.6312729964648!2d77.14778086508149!3d28.580833082438215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b3b69de44b%3A0x95acaea9e5c00f56!2sAir+Force+Golden+Jubilee+Institute!5e0!3m2!1sen!2sin!4v1565083569386!5m2!1sen!2sin" width="100%" title='AFGJI' height="180" frameborder="0" style={{border:0}} allowfullscreen></iframe>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-4 footer_grid">
        <h3>Address</h3>
        <p>Air Force Golden Jubilee Institute
          Subroto Park, New Delhi<br/>
          <strong> Tel. No. :</strong> 011-25692819<br/>
          <strong>Email :</strong> <Link to="mailto:afgjisp@gmail.com">afgjisp@gmail.com</Link><br/>
        </p>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-3 footer_grid">
        <h3>Quick Links</h3>
        <ul>
          <li><Link to="http://cbse.nic.in/" target="_blank">CBSE Website</Link></li>
 
          <li><Link to="/pphelp">Portal (Help?)</Link></li>
          <li><Link to="/erpsoftware">ERP Software </Link></li>
           <li><Link to="/transport">Transport</Link></li>
            <li><Link to="/mission-n-mole">Mission NMOLE</Link></li>
        </ul>
      </div>
      
    </div>
  </div>
</div>
<div className="container-fluid footer_sec_bottom ">
  <p>© Copyright 2017-{currentYear} by Air Force Golden Jubilee Institute. |  Created By <a className="link" href="http://www.entab.in/" target="_blank"> Entab Infotech : CampusCare® </a></p>
</div>
    </>
  );
}

export default Footer;
