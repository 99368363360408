import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import Slider from "react-slick"; 
import LazyLoad from 'react-lazyload';

const HomeEvent = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
     const getData = async () => {
       try {
         const datas = await getEvents();
         console.log(datas);
         setData(datas);
       } catch (error) {
         console.error("Error fetching event data", error);
       }
     };
     getData();
   }, []);

   const settings = {
     autoplay: true,
     infinite: true,
     autoplaySpeed: 2000,
     speed: 1000,
     slidesToShow: 2,
     slidesToScroll: 1,
     arrows: false,
     adaptiveHeight: true,
     responsive: [
       {
         breakpoint: 1600,
         settings: {
           slidesToShow: 2,
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 2,
         }
       },
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 2,
         }
       },
       {
         breakpoint: 600,
         settings: {
           slidesToShow: 1
         }
       }
     ]
   };

   return (
     <>  
       <Slider {...settings}> 
                   
             {data.map((item, index) => (
               <div className="item event_blk" key={index}>
             <LazyLoad>    <img
                   src={`https://webapi.entab.info/api/image/${item?.images}`}
                   className="img-responsive center-block event-cover"
                   alt={item.title}
                 /></LazyLoad>
                 <div className="event-box-text one-bor"> 
                   <div>
                     <h2 className="att">
                       <a href="#" target="_blank" rel="noopener noreferrer">
                       <i className="bi bi-calendar2"></i>
                       </a>
                     </h2>
                     <h3>{item.title}</h3>
                     <p>{item.description}</p>
                     <h5>
                     <i className="bi bi-calendar2"></i> {new Date(item.date).toLocaleString('en-US', { month: 'short' })}, {new Date(item.date).getDate()}, {new Date(item.date).getFullYear()} 
                     <i className="bi bi-geo-alt"></i> {item.venue} 
                     <i className="bi bi-clock"></i> {item.time} 
                     </h5>
                   </div>
                 </div>
               </div>
             ))
             }
          
       
       
       </Slider>
     </>
   );
}

export default HomeEvent;
