
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Home from'./Pages/Home'  
import News from'./Pages/News'  
import Events from'./Pages/Events'  
import Gallery from'./Pages/Gallery'  
import SubGallery from'./Pages/SubGallery'  
import VideoGallery from'./Pages/VideoGallery'  
import PrincipalMessage from'./Pages/PrincipalMessage'  
import ContactUs from'./Pages/contactUs'    
import Library from'./Pages/library' 
import Faculty from'./Pages/faculty'  
import Alumni from'./Pages/alumni'
import SchoolManagement from'./Pages/school-management'
import Pphelp from'./Pages/Pphelp'
import ErpSoftware from'./Pages/ErpSoftware'
import ActivitiesPrimaryWing from'./Pages/activities-primary-wing'
import ActivitiesSeniorWing from'./Pages/activities-senior-wing'
import ActivitiesSpecialWing from'./Pages/activities-special-wing'
import Admission from'./Pages/admission'
import AdmissionInformation from'./Pages/admission_information'
import CBSECircular from'./Pages/CBSE-circular'
import Circular from'./Pages/circular'
import Classesixx from'./Pages/classes_ix_x'
import Classesviviii from'./Pages/classes_VI_VIII'
import Classesxixii from'./Pages/classes_xi_xii'
import Datesheet from'./Pages/datesheet'
import Fee_details from'./Pages/fee_details'
import Infrastructure_primary_wing from'./Pages/infrastructure_primary_wing'
import Infrastructure_senior_wing from'./Pages/infrastructure_senior_wing'
import Infrastructure_special_wing from'./Pages/infrastructure_special_wing'
import MandatoryInformation from'./Pages/mandatory_Information'
import Mediagallery from'./Pages/media_gallery'
import Memories from'./Pages/memories'
import Missionnmole from'./Pages/mission-n-mole'
import Milestone from'./Pages/milestone'
import Ourhistory from'./Pages/ourhistory'
import Ourschool from'./Pages/ourschool'
import Recruitment from'./Pages/recruitment'
import ResultAnalysis from'./Pages/result_analysis'
import RulesRegulation from'./Pages/rules_regulation'
import SchoolCalender from'./Pages/school_calender'
import SchoolInformation from'./Pages/school_Information'
import SpecialHonors from'./Pages/special_honors'
import Syllabus from'./Pages/syllabus'
import Tc from'./Pages/Tc'
import Tender from'./Pages/tender'
import TimeTable from'./Pages/time_table'
import Transport from'./Pages/transport'
import Ukgv from'./Pages/ukg-v'
import Uniformtiming from'./Pages/uniform_timing'
import Virtualtour from'./Pages/virtual_tour'
import Visionmission from'./Pages/vision_mission'
import SpecialWing from'./Pages/specialWing'
import Acheivements from'./Pages/Acheivements'
import QuestionBank from'./Pages/QuestionBank'
import Swarnima from'./Pages/Swarnima'
function App() { 
  return (
      <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/News" element={<News/>} />
        <Route path="/Events" element={<Events/>} />
        <Route path="/Gallery" element={<Gallery/>} />
        <Route path="/SubGallery" element={<SubGallery/>} />
        <Route path="/video_gallery" element={<VideoGallery/>} />
        <Route path="/message_desk" element={<PrincipalMessage/>} /> 
        <Route path="/contact_us" element={<ContactUs/>} />  
        <Route path="/library" element={<Library/>} /> 
        <Route path="/faculty_list" element={<Faculty/>} />  
        <Route path="/alumni" element={<Alumni/>} />
        <Route path="/management" element={<SchoolManagement/>} />
        <Route path="/Pphelp" element={<Pphelp/>} />
        <Route path="/erpsoftware" element={<ErpSoftware/>} />
        <Route path="/activities-primary-wing" element={<ActivitiesPrimaryWing/>} />
        <Route path="/activities-senior-wing" element={<ActivitiesSeniorWing/>} />
        <Route path="/activities-special-wing" element={<ActivitiesSpecialWing/>} />
        <Route path="/admission" element={<Admission/>} />
        <Route path="/admission_information" element={<AdmissionInformation/>} />        
        <Route path="/CBSE-circular" element={<CBSECircular/>} />
        <Route path="/circular" element={<Circular/>} />
        <Route path="/classes_ix_x" element={<Classesixx/>} />
        <Route path="/classes_VI_VIII" element={<Classesviviii/>} />
        <Route path="/classes_xi_xii" element={<Classesxixii/>} />
        <Route path="/datesheet" element={<Datesheet/>} />
        <Route path="/fee_details" element={<Fee_details/>} />
        <Route path="/infrastructure_primary_wing" element={<Infrastructure_primary_wing/>} />
        <Route path="/infrastructure_senior_wing" element={<Infrastructure_senior_wing/>} />
        <Route path="/infrastructure_special_wing" element={<Infrastructure_special_wing/>} />
        <Route path="/mandatory_Information" element={<MandatoryInformation/>} />
        <Route path="/media_gallery" element={<Mediagallery/>} />
        <Route path="/memories" element={<Memories/>} />
        <Route path="/mission-n-mole" element={<Missionnmole/>} />
        <Route path="/milestone" element={<Milestone/>} />
        <Route path="/ourhistory" element={<Ourhistory/>} />
        <Route path="/ourschool" element={<Ourschool/>} />
        <Route path="/recruitment" element={<Recruitment/>} />
        <Route path="/result_analysis" element={<ResultAnalysis/>} />
        <Route path="/rules_regulation" element={<RulesRegulation/>} />
        <Route path="/school_calender" element={<SchoolCalender/>} />
        <Route path="/school_Information" element={<SchoolInformation/>} />
        <Route path="/special_honors" element={<SpecialHonors/>} />
        <Route path="/syllabus" element={<Syllabus/>} />
        <Route path="/tc_issued" element={<Tc/>} />
        <Route path="/tender" element={<Tender/>} />
        <Route path="/time_table" element={<TimeTable/>} />
        <Route path="/transport" element={<Transport/>} />
        <Route path="/ukg-v" element={<Ukgv/>} />
        <Route path="/uniform_timing" element={<Uniformtiming/>} />
        <Route path="/virtual_tour" element={<Virtualtour/>} />
        <Route path="/vision_mission" element={<Visionmission/>} />
        <Route path="/specialWing" element={<SpecialWing/>} />
        <Route path="/Acheivements" element={<Acheivements/>} />
        <Route path="/QuestionBank" element={<QuestionBank/>} />
        <Route path="/Swarnima" element={<Swarnima/>} />
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
