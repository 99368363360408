import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick"; 
import { getCircular } from "../Service/Api";

const Circular = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getCircular();
      const currentYear = new Date().getFullYear(); // Get the current year
      const filteredData = datas.filter(item => {
        const itemYear = new Date(item.date).getFullYear(); // Get the year of the circular
        return itemYear === currentYear; // Only return circulars from this year
      });
      setData(filteredData);
    };
    getData();
  }, []);

  const placeholderEntries = [
    { title: "News Title", description: "Stay Tuned For More Updates" },
    { title: "News Title", description: "Stay Tuned For More Updates" },
    { title: "News Title", description: "Stay Tuned For More Updates" }
  ];

  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="eventbg">
        <Slider {...settings}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <div className="item" key={index}>
                <div className="circularbox">
                  <h3>
                    <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank">
                      <i className="bi bi-paperclip"></i>
                    </Link> 
                    {item.title}
                  </h3>
                </div> 
              </div>
            ))
          ) : (
            placeholderEntries.map((item, index) => (
              <div className="item" key={index}>
                <div className="circularbox">
                  <h3>
                    <Link to="#">
                      <i className="bi bi-paperclip"></i>
                    </Link> 
                    Stay Tuned For More Updates
                  </h3>
                </div> 
              </div>
            ))
          )}
        </Slider> 
      </div>
    </>
  );
};

export default Circular;
