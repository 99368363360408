import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb"; 

const SchoolCalender = () => {
  // React state to track the active tab
  const [activeTab, setActiveTab] = useState('csw');

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="School Calendar" breadcrumbName="School Calendar" />

      <div className="container body_height">
        <div className="row">
          <div className="col-md-3 leftmenu">
            <div className="leftmenubg">
              <p className="menuhead">Important Links</p>
            </div>
            <div id="events">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className={activeTab === 'csw' ? 'active' : ''}>
                  <a href="#csw" onClick={() => setActiveTab('csw')} aria-controls="csw" role="tab">Senior Wing</a>
                </li>
                <li role="presentation" className={activeTab === 'cpw' ? 'active' : ''}>
                  <a href="#cpw" onClick={() => setActiveTab('cpw')} aria-controls="cpw" role="tab">Primary Wing</a>
                </li>
                <li role="presentation" className={activeTab === 'csrw' ? 'active' : ''}>
                  <a href="#csrw" onClick={() => setActiveTab('csrw')} aria-controls="csrw" role="tab">Special Wing</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="tab-content">
              {/* Senior Wing Tab */}
              {activeTab === 'csw' && (
                <div role="tabpanel" className="tab-pane active" id="csw">
                  <div className="col-md-12">
                    <h3 className="sub_head">Calendar Senior Wing</h3>
                    <object
                      data="https://webapi.entab.info/api/image/AFGJI/public/pdf/Calendar-Seniors-Wing.pdf"
                      type="application/pdf"
                      width="100%"
                      height="810"
                      style={{ border: '2px solid #c1c1c1' }}
                    />
                  </div>
                </div>
              )}

              {/* Primary Wing Tab */}
              {activeTab === 'cpw' && (
                <div role="tabpanel" className="tab-pane active" id="cpw">
                  <div className="col-md-12">
                    <h3 className="sub_head">Calendar Primary Wing</h3>
                    <object
                      data="https://webapi.entab.info/api/image/AFGJI/public/pdf/Calendar-Primary-Wing.pdf"
                      type="application/pdf"
                      width="100%"
                      height="810"
                      style={{ border: '2px solid #c1c1c1' }}
                    />
                  </div>
                </div>
              )}

              {/* Special Wing Tab */}
              {activeTab === 'csrw' && (
                <div role="tabpanel" className="tab-pane active" id="csrw">
                  <div className="col-md-12">
                    <h3 className="sub_head">Calendar Special Wing</h3>
                    <object
                      data="https://webapi.entab.info/api/image/AFGJI/public/pdf/Calendar-Special-Wing.pdf"
                      type="application/pdf"
                      width="100%"
                      height="810"
                      style={{ border: '2px solid #c1c1c1' }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SchoolCalender;
