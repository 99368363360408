import React, { useEffect, useRef } from "react";

const VideoPopUp = () => {
  const videoEl = useRef(null);
  const handleClose = () => {
    const modal = document.getElementById("exampleModalToggle");
    modal.style.display = "none";
    modal.classList.remove("show");
  };
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div
    className="popup"
    id="exampleModalToggle"
    aria-labelledby="exampleModalToggleLabel"
    tabIndex="-1"
    aria-hidden="true"
  >
    <div
      className="modal show"
      id="exampleModalToggle"
      tabIndex="-1"
      aria-labelledby="exampleModalToggleLabel"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog ">
        <div className="modal-content">
        <div id="modalH" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                {/* Close Button */}
                <button
  type="button"
  className="btn-close"
  onClick={handleClose}
  aria-label="Close"
></button>

                {/* Modal Content */}
                <div className="modal-body">
                  <div className="card modalcard mb-0">
                    <div className="card-body">
                      {/* Video */}
                      {/* <iframe src="https://youtu.be/J-3my1Vwv_U?si=332cIzNnQdlQj-Ja" width={100} height={100} /> */}
                      <iframe style={{
                              maxWidth: "100%",
    width: "100%",
    margin: "0px auto",
    minHeight: "420px",
    maxHeight:" 80%",
    background:" rgb(0, 0, 0)",
    objectFit: "fill",
                        }} muted
                        controls src="https://www.youtube.com/embed/J-3my1Vwv_U?si=78mEMK4A6LxVuauv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen autoplay></iframe>
                      {/* <video
                        style={{
                          maxWidth: "100%",
                          width: "100%",
                          margin: "0 auto",
                          height: "600px",
                          maxHeight: "80%",
                          background: "#000",
                        }}
                        playsInline
                        loop
                        muted
                        controls
                        alt="All the devices"
                        src="https://webapi.entab.info/api/image/AFGJI/public/Images/newYearVideo.mp4"
                        ref={videoEl}
                      /> */}
                      {/* Message */}
                      <p className="text-center mt-3" style={{height: "auto", display: "block"}}>
                      DD News Program "GOOD NEWS JAZBA INDIA KA" - AFGJI Special Wing segment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default VideoPopUp;
