import React, { useEffect, useState } from 'react'; 
import { getGallery } from '../Service/Api';
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from 'react-lazyload';

const Gallery = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getGallery();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Fetched data:", data); // Check what data you are getting from API
  }, [data]);

  const emptyArray = [
    { title: "Gallery Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
    { title: "Gallery Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
    { title: "Gallery Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
  ];

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="d-block">
        <Slider {...settings}>
        {data?.length > 0 ? (
          data.filter((div) => div.category === "PhotoGallery").map((item, index) => (
            <div className="no-pad galoverlay" key={index}> 
              <a href="/Gallery"> 
              <LazyLoad>  <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-responsive gal_2" alt="AFGJI" /></LazyLoad>  
                <div className="overlay">
                  <div className="text">View Gallery</div>
                </div>
              </a>
            </div>
          ))
        ) : (
          emptyArray.map((index) => (
            <div className="no-pad galoverlay" key={index}> <a href="/Gallery">
              <LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/gallery/2.jpg" className="img-responsive gal_2" alt="AFGJI"/></LazyLoad>
            <div className="overlay">
              <div className="text">View Gallery</div>
            </div>
            </a></div>
          ))
        )}
        </Slider>
      </div>
    </>
  );
};

export default Gallery;
