import React from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
const specialWing = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Special Wing" breadcrumbName="Special Wing" />
            <div className="container toppad-30 min-height-container">
                <div className="row">
                    <div className="col-md-12">
                    {/* <h5><b>Special Wing Contact Info</b></h5> */}
                    <ul className="checkList">
                        <li>Air Force Golden Jubilee Institute was set up under the aegis of IAF Educational &amp; Cultural Society in the year 1986. The Institute is located on Arjan Path in Subroto Park, Delhi Cantt (Western Air Command, Indian Air Force).</li>
                        <li>The Special Wing (Special Education School) of AFGJI was conceptualized in the year 1984 and it formally started in 1985 with just 30 students. It was in the year 1986 that the general wing started (CBSE affiliated) and the intellectually challenged students of Special Wing were socially integrated with their normal peers in as many activities as possible which is being appreciated not only nationally but internationally.</li>
                        
                    </ul>

                    

                    </div>
                    <div className="col-md-4">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing1.jpg" className="img-fluid special_img" /></LazyLoad> 
                    </div>
                    <div className="col-md-4">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing2.jpg" className="img-fluid special_img" /></LazyLoad>
                    </div>
                    <div className="col-md-4">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing3.jpg" className="img-fluid special_img" /></LazyLoad>
                    </div>
                    <div className="col-md-12">
                        <ul className="checkList">
                            <li>The Special Wing of AFGJI is registered as Special Education School under RPWD Act 2016 vide Regn number F.N.82/1352/RPwD-Regd/ADIII/DSW/2020-21/813 dtd 13 Apr 2021.</li>
                            <li>The motto is to make Children with Special Needs (CWSN) – personally independent, socially competent, skill efficient and economically productive. We train them to be a support system and a helping hand to the family and the society.</li>
                            <li>This institute provides special education and training to special needs children with Intellectual Disabilities like Autism, Down Syndrome, Mentally Challenged, Cerebral Palsy, etc. This also provides a suitable atmosphere and a platform for all the students for their holistic development. The therapies imparted are special education classes with Smart Touch Panel Speech Therapy, Occupational therapy, Physiotherapy department (which are complimentary and need based). There is a dedicated assembly and celebration hall, play ground, inclusive -bigger playground, cafeteria, assembly ground, hall, water points &amp; transport. </li>
                            <li>The AFGJI -Special Wing (Special Education School) has the following departments:-
                                <ol className="list_none">
                                    <li><b>Special Education Classes: </b> Special education groups / classes with maximum strength of 10-14 students in each class are made. These groups are mostly based on age criteria and level criteria also. We follow Function Assessment checklist for programming by National Institute for the Empowerment of Persons with Intellectual Disabilities, Secunderabad.
                                    <br />The special education groups are as follows: -
                                    <ul>
                                        <li>Primary Group 		:	6 to 10 years</li>
                                        <li>Secondary group		:	11 to 14 years</li>
                                        <li>Pre-vocational group  	:	15 to 18 years</li>
                                    </ul>
                                        
                                    </li>
 
                                    </ol>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing4.jpg" className="img-fluid special_img" /></LazyLoad>
                    </div>
                    <div className="col-md-4">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing5.jpg" className="img-fluid special_img" /></LazyLoad>
                    </div>
                    <div className="col-md-4">
                    <LazyLoad>    <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing6.jpg" className="img-fluid special_img" /></LazyLoad>
                    </div>

                    <div className="col-md-12">
                        <p>The special education groups and classes are completely based on skill development programme. This is to impart education and training to empower the children with intellectual disabilities to make them independent, self reliant and socially competent in different skills like activities of daily living, reading, writing, mathematical basic skills, all functional skills, social skills, language development communication skills, motor areas like gross and fine motor development etc.</p>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="spl_blk">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing7.jpg" className="img-fluid special_img" /></LazyLoad>
                        <h3>Speech Therapy Department  </h3>
                        <p>Special Wing has two speech therapy units, one is based on one to one session programme on software-Dr Speech and another unit is based on developing social skills, community level communication and language development which is based on group speech trainer.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="spl_blk">
                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing8.jpg" className="img-fluid special_img" /></LazyLoad>
                        <h3>Occupational Therapy Department</h3>
                        <p>     The  Occupational therapy department is  equipped to provide needful therapeutic  intervention to the students who need  sensory integration, stimulation, perception  and cognition, improving posture, motor skills both gross and fine.</p>
                        </div>
                    </div>
                    <div className="col-md-4"><div className="spl_blk">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing9.jpg" className="img-fluid special_img" /></LazyLoad>
                        <h3>Physiotherapy Department</h3>
                        <p>Special Wing physiotherapy department is equipped with different therapeutic equipment to provide needful physiotherapy to the students of Special Wing to attain the physical independence.
This is based on concept to give training, weight training, balancing, etc.</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <ul className="checkList">
                            <li>Additional Skill Training Programme
                                
                                <ol className="list_none">
                                    <li><b>Vocational Skill Training</b>– Students are given training in these aspects also to develop the concept of manufacturing, assembly, preparations, purchasing, stocking and selling. The vocational aspects in which our students are given exposure are – Rakhi making, Necklace making, Artificial jewellery Making, Coaster making, Cooking
                      without fire, Envelope making, Block printing, etc. </li>
                      <p><b>All these skills are imparted by skilled teachers of Senior Wing in respective fields.</b></p>
                                    <li><b>Dance</b></li>
                                    <li><b>	Art Classes  </b></li>
                                    <li><b>Library Activities </b></li>
                                    <li><b>Special Wing Computer Centre</b></li>
                                    <li><b>Music</b></li>
                                </ol>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing10.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-4">
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing11.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-12">
                        <ul className="checkList">
                            <li>Activities conducted in Special Wing
                                 
                                <ol className="list_none">
                                    <li><b>Visits </b>– The students were taken on a exposure cum orientation trip to different place/parks/areas like – Bikano Restaurant, Stadium, Trishul Park, Vandana Park, WAC play school, Unit School, Post Office,Children’s Park near SBI and Canara Bank, Eco park, Shopping complex, Laundry area visit, WAC Sports Ground, Umeed, Kiran Nadar Museum, Metro ride, Asola Bird Century.</li>
                                    
                                    </ol>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing12.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-3">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing13.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-3">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing14.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-3">
                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing15.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-12">
                        <ul className="checkList">
                            <li><b> Share and Care Programme -  </b>     General Wing Students of Senior Wing and
Primary Wing of all 03 inclusive Air Force schools- Air Force Golden Jubilee Institute, Air Force Bal Bharati School, The Air Force School visit Special Wing
as per the day allotted to them. These students of Inclusive Wing get a chance to sit, spend time, interact with the Special Wing students, make friendship with them, learn about them, know their names, understand their activities they may do, the strengths and weaknesses, their learning style, their food pattern and precautions and other measures they may keep in their mind when around them. This is a very effective sensitization technique and the very special children also enjoy with the General Wing learners.
                                  
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing16.jpg"  className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-3">
                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing17.jpg" className="img-fluid special_img" />  </LazyLoad> 
                        
                    </div>
                    <div className="col-md-3">
                    <LazyLoad>    <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing18.jpg"  className="img-fluid special_img" /> </LazyLoad>
                        
                    </div>
                    <div className="col-md-3">
                    <LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/specialWing19.jpg" className="img-fluid special_img" /></LazyLoad>
                        
                    </div>
                    <div className="col-md-12">
                        <p><b>Contact Info</b></p>
                    <p><span><i class="bi bi-telephone-fill"></i><a class="" href="tel:9013 430031">+91 9013 430031</a> </span></p>
                        </div>
                        
                </div>
            </div>
            <Footer />
        </>
    )
}
export default specialWing