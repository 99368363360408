import { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import Header from "../Component/Header";
import LazyLoad from 'react-lazyload';
import Footer from "../Component/Footer";  
const CBSECircular = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [yearRanges, setYearRanges] = useState([]); 
  
    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear}`;
      setSelectedYear(Year);
      console.log("get current Year", Year);
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        const circularData = await getCircular();
        setData(circularData);
        const circularYears = extractYears(circularData);
        setYearRanges(circularYears);
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      if (data.length === 0) {
        return;
      }
    
      let filteredData = data.filter((item) => item.category === "CBSECircular");
      let currentFilteredData = filterDataByYear(filteredData, selectedYear);
    
      // If no data for the selected year, fallback to previous year with data
      if (currentFilteredData.length === 0) {
        const availableYears = yearRanges;
        for (let i = 0; i < availableYears.length; i++) {
          const fallbackYear = availableYears[i];
          currentFilteredData = filterDataByYear(filteredData, fallbackYear);
          if (currentFilteredData.length > 0) {
            setSelectedYear(fallbackYear); // Update selected year to the fallback year
            break;
          }
        }
      }
    
      // Sort the currentFilteredData by date in descending order (latest on top)
      currentFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
    
      setFilteredData(currentFilteredData);
    }, [selectedYear, data, yearRanges]);
    
    
  
    const filterDataByYear = (data, year) => {
      if (year === "All") return data;
      return data.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const itemYear = new Date(item.date).getFullYear();
          return parseInt(year) === itemYear;
        }
        return false;
      });
    };
  
    const extractYears = (data) => {
      const years = data
        .filter((item) => item.category === "Circular")
        .map((item) => {
          if (item.date && item.date.trim() !== "") {
            return new Date(item.date).getFullYear().toString();
          }
          return null;
        })
        .filter((year, index, self) => year && self.indexOf(year) === index)
        .sort((a, b) => b - a); // Sort years in descending order
      return years;
    };
    return (
        <>
            <Header />
            <div className="container-fluid innerbg">
  <div className="container">
    <div className="row">
      <div className="col-xs-6 col-sm-6 col-md-6">
        <h2 className="innerhead">CBSE Circular</h2>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-6"> 
      <select
                t id="ddlYear"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  
                >
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select> 
          {/* <asp:DropDownList ID="ddlYear" runat="server"  AutoPostBack="True" OnSelectedIndexChanged="ddlYearchanged" >
           </asp:DropDownList> */}
{/* <%--<select id="ddlYear">
          <option selected value="Three">2024</option>
          <option value="Two">2023</option>
        </select>--%> */}
      </div>
    </div>
  </div>
</div>
<div className="container body_height Circular-space">
   <div className="row">
      <div className="col-md-12">
         
         <div id="showThree" className="myDiv selected_year">
            <div className="row"> 

                {filterData.length > 0 ? (
                  filterData.map((item, index) => (


                    <div className="col-md-4 col-sm-6 col-xs-6 dips-grid"  key={index}>
                  <a href={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank">
                     <div className="panel panel-default">
                        <div className="panel-body">
                           <div className="row">
                              <div className="col-md-3 col-sm-3">
                              <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/log.jpg" className="img-responsive center-block"/></LazyLoad>
                              </div>
                              <div className="col-md-9 col-sm-9">
                                 <p className="panel-ppad">{item.title}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>


                    
                  ))
                ) : (
                    <div className="col-md-4 col-sm-6 col-xs-6 dips-grid">
                    <a href="#" target="_blank">
                       <div className="panel panel-default">
                          <div className="panel-body">
                             <div className="row">
                                <div className="col-md-3 col-sm-3">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/log.jpg" className="img-responsive center-block"/></LazyLoad>
                                </div>
                                <div className="col-md-9 col-sm-9">
                                   <p className="panel-ppad"> CBSE Circular Title</p>
                                </div>
                             </div>
                          </div>
                       </div>
                    </a>
                 </div>
                )}

 
    

            </div>
         </div>
       
     
      </div>
   </div>
</div>
                <Footer />
        </>
    )
}

export default CBSECircular