import React, { useEffect, useState } from "react";
import { getGalleryInner } from "../Service/Api";
import Slider from "react-slick"; 
import LazyLoad from 'react-lazyload';

const Acheivement = () => {
   const [data, setData] = useState([]);
   const convertDateToDDMMYYYY = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
   useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getGalleryInner();
      const galleryData = galleryData1.filter(item => item.category === 'Achievements');

      // Convert date to DD MM YYYY format
      const formattedGalleryData = galleryData.map(item => ({
        ...item,
        formattedDate: item.date ? convertDateToDDMMYYYY(item.date) : null,
      }));

      setData(formattedGalleryData);
 
    
 
 
    };

    fetchData();
  }, []);

   const settings = {
     autoplay: true,
     infinite: true,
     autoplaySpeed: 2000,
     speed: 1000,
     slidesToShow: 1,
     slidesToScroll: 1,
     arrows: false,
     adaptiveHeight: true,
     responsive: [
       {
         breakpoint: 1600,
         settings: {
           slidesToShow: 1,
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 1,
         }
       },
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 1,
         }
       },
       {
         breakpoint: 600,
         settings: {
           slidesToShow: 1
         }
       }
     ]
   };

   return (
     <>  
       <Slider {...settings}> 
                   
             {data.length>0 ? (data.map((item, index) => (
               <div className="item event_blk acheivement_blk" key={index}>
             <LazyLoad> <img
                   src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}
                   className="img-responsive center-block event-cover"
                   alt={item.title}
                 /></LazyLoad>
                 <div className="event-box-text one-bor"> 
                   <div>
                     <h2 className="att">
                       <a href="#" target="_blank" rel="noopener noreferrer">
                       <i className="bi bi-calendar2"></i>
                       </a>
                     </h2>
                     <h3>{item.title}</h3>
                     <p>{item.description}</p>
                     <h5>
                     <i className="bi bi-calendar2"></i> {new Date(item.date).toLocaleString('en-US', { month: 'short' })}, {new Date(item.date).getDate()}, {new Date(item.date).getFullYear()} 
                      
                     </h5>
                   </div>
                 </div>
               </div>
             ))):(
                <div className="item event_blk acheivement_blk" >
             <LazyLoad> <img
                   src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/our-school.jpg"
                   className="img-responsive center-block event-cover"
                   alt="AFGJI"
                 /></LazyLoad>
                 <div className="event-box-text one-bor"> 
                   <div>
                     <h2 className="att">
                       <a href="#" target="_blank" rel="noopener noreferrer">
                       <i className="bi bi-calendar2"></i>
                       </a>
                     </h2>
                     <h3>Achievements Title</h3>
                     <p>Stay Tuned for Latest Updates</p>
                     <h5>
                     <i className="bi bi-calendar2"></i> DD.MM.YYYY
                      
                     </h5>
                   </div>
                 </div>
               </div>
             )
             }
          
       
       
       </Slider>
     </>
   );
}

export default Acheivement;
