
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
const Visionmission = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Vision & Mission" breadcrumbName="Vision & Mission" />     
            <div className="container body_height">
                <div className="row">
                    <div className="col-sm-7 col-md-8">
                        <h3 className="subhead">Vision</h3>
                        <p>The School’s vision is to nurture students by creating a learning environment that cultivates knowledge and fortifies character. Holistic education will transform the young into global citizens who will contribute to humanity in meaningful ways.</p>
                        <h3 className="subhead">Mission</h3>
                        <p>The School has an unwavering commitment to adaptability and innovation and is dedicated to imparting quality and inclusive education by drawing inspiration from a tapestry of educational philosophies.</p>
                        {/* <LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/emblem.png" className="img-responsive center-block emblem_icon" alt="AFGJI" /></LazyLoad> */}
                    </div>
                    <div className="col-sm-5 col-md-4"><LazyLoad> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/emblem.png" className="img-responsive center-block" alt="AFGJI" /></LazyLoad> </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Visionmission