import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postTransferCertificate } from "../Service/Api";
import Header from "../Component/Header";
import Breadcrumb from "../Component/Breadcrumb"; 
import { type } from "@testing-library/user-event/dist/type";
const Tc = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://webapi.entab.info/api/image/${response.data}`)
          .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
          .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = type === "pdf" ? 'tc.jpg' : 'tc.pdf';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
          })
          .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="TC Issued" breadcrumbName="TC Issued" />


      <div className="body_height">
        <div className="container">

          <div className="row">
            <div className="col-lg-6">
              <div className="tc">
                <div>
                  <form onSubmit={handleSubmit} className="tc" data-aos="fade-left" data-aos-duration="2000">
                    <div className="text_box_sec form-group"> <input className="form-control" id="name" value={name} onChange={handleFullNameChange} type="text" placeholder="Name" /></div>
                    <div className="text_box_sec form-group"> <input className="form-control" id="admission" value={admission} onChange={handleAdmissionNoChange} type="text" placeholder="Admission Number" /></div>
                    <div className="text_box_sec form-group">
                      {/* <input type="submit" value="VIEW DETAILS" /> */}
                      <input type="submit" name="btnshwtcdetails" value="View Details" id="btnshwtcdetails" className="button-default"></input>
                    </div>
                    {error && <p className="error">{error}</p>}
                  </form>
                </div>
                <ul class="checkList">
                  <li><a href="https://webapi.entab.info/api/image/AFGJI/public/pdf/TC-APPLICATION-SENIORWING.pdf" target="_blank">TC Application Form for Senior Wing</a></li>
                  <li><a href="https://webapi.entab.info/api/image/AFGJI/public/pdf/TCAPPLICATION-PRIMARYWING.pdf" target="_blank">TC Application Form for Primary Wing</a></li>
                  <li><a href="https://webapi.entab.info/api/image/AFGJI/public/pdf/TCAPPLICATION-SPECIALWING.pdf" target="_blank">TC Application Form for Special Wing </a></li>


                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tcblock">

                <iframe src="https://webapi.entab.info/api/image/AFGJI/public/pdf/TC-Sample.pdf#toolbar=0" width="100%" height="500" ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tc;
