
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import Breadcrumb from "../Component/Breadcrumb";
const Classesxixii = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Classes XI & XII" breadcrumbName="Classes XI & XII" />

      <div className="container body_height">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="subhead">Time Table</h3>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/MID-TERM-EXAMINATION-CLASSES-VI-TO-XII.pdf" target="_blank">Mid Term Examination</Link></div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Senior/class-11.pdf" target="_blank">Class 11th</Link> </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Senior/class-12.pdf" target="_blank">Class 12th</Link> </div>
          </div>
        </div>

        <div className="clr20"></div>

        <div className="row">
          <div className="col-lg-12">
            <h3 className="subhead">Syllabus</h3>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 11.pdf" target="_blank">Class 11th </Link> </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 12.pdf" target="_blank">Class 12th </Link> </div>
          </div>

        </div>


      </div>
      <Footer />
    </>
  )
}

export default Classesxixii