import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';

const Infrastructure_special_wing = () => {
    // Set up React state for managing the active tab
    const [activeTab, setActiveTab] = useState('isw1');

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Infrastructure Special Wing" breadcrumbName="Infrastructure Special Wing" />

            <div className="container body_height">
                <div className="row">
                    <div className="col-md-3  leftmenu">
                        <div className="leftmenubg">
                            <p className="menuhead">Important Links</p>
                        </div>
                        <div id="events">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className={activeTab === 'isw1' ? 'active' : ''}>
                                    <Link
                                        to="#isw1"
                                        onClick={() => setActiveTab('isw1')}
                                        aria-controls="isw1"
                                        role="tab"
                                    >
                                        Therapy Rooms
                                    </Link>
                                </li>
                                <li role="presentation" className={activeTab === 'isw2' ? 'active' : ''}>
                                    <Link
                                        to="#isw2"
                                        onClick={() => setActiveTab('isw2')}
                                        aria-controls="isw2"
                                        role="tab"
                                    >
                                        Multi Purpose Hall (Chanakya)
                                    </Link>
                                </li>
                                <li role="presentation" className={activeTab === 'isw3' ? 'active' : ''}>
                                    <Link
                                        to="#isw3"
                                        onClick={() => setActiveTab('isw3')}
                                        aria-controls="isw3"
                                        role="tab"
                                    >
                                        Sick Bay
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="tab-content">
                            {activeTab === 'isw1' && (
                                <div role="tabpanel" className="tab-pane active" id="isw1">
                                    <div className="col-md-12">
                                        <h3 className="sub_head">Physiotherapy Department</h3>
                                        <p>Special Wing Physiotherapy Department is equipped with different therapeutic
                                            equipments to provide needful physiotherapy to the students of Special Wing to
                                            attain the physical independence. Physiotherapists are working in this department
                                            with the support of Air Force</p>
                                        <div className="height-40"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/Physiotherapy.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/Physiotherapy1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/Physiotherapy2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="height-40"></div>
                                        <h3 className="sub_head">Occupational Therapy Department</h3>
                                        <p>The Occupational Therapy Department is equipped to provide needful therapeutic
                                            intervention to the students who need sensory integration, stimulation, perception
                                            and cognition, improving posture, motor skills both gross and fine. Aids and
                                            appliances are made on order for special children with specific needs.</p>
                                        <div className="height-40"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/OCCUPATIONAL.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/OCCUPATIONAL1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/OCCUPATIONAL2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="height-40"></div>
                                        <h3 className="sub_head">Speech Therapy Department</h3>
                                        <p>Special Wing has two Speech Therapy units, one is based on one to one session
                                            programmed on software Dr. Speech and another unit is based on developing social
                                            skills, community level communication and language development which is based on
                                            group speech trainer.</p>
                                        <div className="height-40"></div>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/speech1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/speech2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/speech3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'isw2' && (
                                <div role="tabpanel" className="tab-pane active" id="isw2">
                                    <h3 className="sub_head">Multi Purpose Hall (Chanakya)</h3>
                                    <p>There is a dedicated hall for the children of Special Wing for various activities/functions. Students actively participate and enjoy Music, Dance and other activities in the Hall.</p>
                                    <div className="height-40"></div>
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/multi.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/multi1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/multi2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'isw3' && (
                                <div role="tabpanel" className="tab-pane active" id="isw3">
                                    <h3 className="sub_head">Sick Bay</h3>
                                    <p>Any student who is feeling unwell during school hours must report to sick bay. The
                                        sick bay is equipped with first aid facilities and a doctor is available during school
                                        hours.</p>
                                    <div className="height-40"></div>
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/sick1.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/sick2.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/sick3.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Infrastructure_special_wing;
