import React, { useState } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import Breadcrumb from "../Component/Breadcrumb"; 

const TimeTable = () => {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("datesheets_1");

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Time Table" breadcrumbName="Time Table" />

      <div className="container body_height">
        <div className="row"> 
            <div className="col-md-3 leftmenu">
              <div className="leftmenubg">
                <p className="menuhead">Important Links</p>
              </div>
              <div id="events">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className={activeTab === "datesheets_1" ? "active" : ""}>
                    <Link to="#datesheets_1" onClick={() => setActiveTab("datesheets_1")} aria-controls="datesheets_1" role="tab">
                      Primary Wing
                    </Link>
                  </li>
                  <li role="presentation" className={activeTab === "datesheets_2" ? "active" : ""}>
                    <Link to="#datesheets_2" onClick={() => setActiveTab("datesheets_2")} aria-controls="datesheets_2" role="tab">
                      Senior Wing
                    </Link>
                  </li>
                  <li role="presentation" className={activeTab === "datesheets_3" ? "active" : ""}>
                    <Link to="#datesheets_3" onClick={() => setActiveTab("datesheets_3")} aria-controls="datesheets_3" role="tab">
                      Special Wing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="tab-content">
                {activeTab === "datesheets_1" && (
                  <div role="tabpanel" className="tab-pane active" id="datesheets_1">
                    <h3 className="sub_head">Primary Wing Datesheet</h3>


                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/primary/DATE-SHEET-Mid-Term-2024.pdf" target="_blank">Date Sheet of the Mid-Term exam for classes I to V </Link> </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/primary/DATE-SHEET-PT1-2024.pdf" target="_blank"> PERIODIC TEST - 1 Datesheet SESSION 2024-25 CLASSES I to V  </Link> </div>
                    </div>

                    <div className="cl25"></div>
                  </div>
                )}

                {activeTab === "datesheets_2" && (
                  <div role="tabpanel" className="tab-pane active" id="datesheets_2">

                    <h3 className="sub_head">Senior Wing Datesheet</h3>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/mid-term-vi-xii-2024-25.pdf" target="_blank">Mid Term  Exam for Classes VI to XII (2024-25)  </Link> </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-XI-2024-25.pdf" target="_blank"> PERIODIC TEST- I CLASS XI  SESSION- (2024-25)   </Link> </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-XI-2024-25.pdf" target="_blank"> PERIODIC TEST- II  Class X &amp; XII SESSION- (2024-25) </Link> </div>
                    </div>


                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-vi-ix-2024-25.pdf" target="_blank"> PERIODIC TEST- I CLASS VI TO IX SESSION- (2024-25) </Link> </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-X-XII-2024-25.pdf" target="_blank"> Periodic Test - I CLASS X &amp; XII SESSION- (2024-25)  </Link> </div>
                    </div>
                  </div>
                )}

                {activeTab === "datesheets_3" && (
                  <div role="tabpanel" className="tab-pane active" id="datesheets_3">
                    <h3 className="sub_head">Special Wing Datesheet</h3>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/annual-datesheet-special-wing.pdf" target="_blank">Annual Datesheet Special Wing</Link> </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> 
      </div>
      <Footer />
    </>
  );
};

export default TimeTable;
