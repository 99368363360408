import { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
import {Link} from 'react-router-dom'
const Recruitment = () => {
    const [data, setData] = useState([]);  
 
  
    useEffect(() => {
      const fetchData = async () => {
        const circularData1 = await getCircular();
        const circularData= circularData1.filter((item) => item.category === "Recruitment")
        setData(circularData);  
      };
      fetchData();
    }, []);
  
 
    
    
  
    
   
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Recruitment" breadcrumbName="Recruitment" />
            
<div className="container body_height Circular-space">
   <div className="row">
      <div className="col-md-12">
         
         <div id="showThree" className="myDiv selected_year">
            <div className="row"> 
            <div className="col-md-12">

           
            <div className="table-responsive">
                            <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th width="60%">Title</th>
                                    <th>Date</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                     <tr key={index}>
                        <td>{item.title}</td>
                        <td>{item.date}</td>
                        <td><Link to={`https://webapi.entab.info/api/image/${item.attachments}`} download target="_blank"> Download</Link></td>
                     </tr>

                  


                    
                  ))
                ) : (
                   <tr>
<td>-</td>
<td>-</td>
<td>-</td>
                   </tr>
                )}
                </tbody>
                
</table>
</div>
    
</div>
            </div>
         </div>
       
     
      </div>
   </div>
</div>
                <Footer />
        </>
    )
}

export default Recruitment