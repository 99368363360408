import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';
import { getCircular } from '../Service/Api';

const QuestionBank = () => {
    const [data, setData] = useState([]); // Stores unique class data.
    const [originalData, setOriginalData] = useState([]); // Stores the full data for filtering.
    const [activeTab, setActiveTab] = useState(""); // Active tab class.

    useEffect(() => {
        const getData = async () => {
            try {
                const circularData = await getCircular();
                const filteredQuestionBank = circularData.filter(
                    (item) => item.category === "QuestionPapers"
                );

                // Remove duplicates based on the `class` property.
                const uniqueClasses = filteredQuestionBank.reduce((acc, current) => {
                    const existing = acc.find(item => item.class === current.class);
                    if (!existing) {
                        acc.push(current);
                    }
                    return acc;
                }, []);

                // Sort classes in ascending order (numeric or alphabetic).
                uniqueClasses.sort((a, b) => {
                    if (isNaN(a.class) || isNaN(b.class)) {
                        // Sort alphabetically for non-numeric class names.
                        return a.class.localeCompare(b.class);
                    }
                    // Sort numerically for numeric class names.
                    return Number(a.class) - Number(b.class);
                });

                console.log("Sorted Unique Classes:", uniqueClasses);

                setData(uniqueClasses); // Set sorted unique classes for the tab list.
                setOriginalData(filteredQuestionBank); // Store the full data for filtering.

                // Set the default active tab to the first class (after sorting).
                if (uniqueClasses.length > 0) {
                    setActiveTab(uniqueClasses[0].class);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getData();
    }, []);

    const handleTabClick = (classId) => {
        setActiveTab(classId);
    };

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Question Bank" breadcrumbName="Question Bank" />

            <div className="container body_height">
                <div className="row">
                    {/* Left Menu */}
                    <div className="col-md-3 leftmenu">
                        <div className="leftmenubg">
                            <p className="menuhead">Classes</p>
                        </div>
                        <div id="events">
                            <ul className="nav nav-tabs" role="tablist">
                                {data.map((item, index) => (
                                    <li
                                        key={index}
                                        role="presentation"
                                        className={activeTab === item.class ? "active" : ""}
                                    >
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleTabClick(item.class);
                                            }}
                                            to="#"
                                        >
                                            {item.class}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Content Area */}
                    <div className="col-md-9">
                        <div className="row">
                            <div className='col-md-12'>
                            <h3 class="sub_head">{activeTab}</h3>
                            </div>
                            {originalData.length>0 ? (originalData
                                .filter(item => item.class === activeTab)
                                .map((item, index) => (
                                    <>
                                   
                                    <div
                                        className="col-md-6 col-sm-6 col-xs-6 dips-grid"
                                        key={index}
                                    >
                                        <a
                                            href={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-md-3 col-sm-3">
                                                            <LazyLoad>
                                                                <img
                                                                    src="https://webapi.entab.info/api/image/AFGJI/public/Images/log.jpg"
                                                                    className="img-responsive center-block"
                                                                    alt="Thumbnail"
                                                                />
                                                            </LazyLoad>
                                                        </div>
                                                        <div className="col-md-9 col-sm-9">
                                                            <p className="panel-ppad">{item.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div></>
                                ))):(<>
                                    <div
                                        className="col-md-6 col-sm-6 col-xs-6 dips-grid"
                                       
                                    >
                                        <a
                                            href="/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-md-3 col-sm-3">
                                                            <LazyLoad>
                                                                <img
                                                                    src="https://webapi.entab.info/api/image/AFGJI/public/Images/log.jpg"
                                                                    className="img-responsive center-block"
                                                                    alt="Thumbnail"
                                                                />
                                                            </LazyLoad>
                                                        </div>
                                                        <div className="col-md-9 col-sm-9">
                                                            <p className="panel-ppad">Title</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div></>)}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default QuestionBank;
