
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';
const SpecialHonors = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Special Honors" breadcrumbName="Special Honors" />

      <div className="container body_height">
        <div className="row">

          <div className="col-md-12">
            <p><b>Mr. Rahul Sofat, PGT-Mathematics of Air Force Golden Jubilee Institute, Subroto Park, New Delhi-110010</b> along with thirty four teachers across the nation were<b> awarded CBSE Teachers Award 2018-19 by HRD minister Ramesh Pokhriyal</b> for their valuable & commendable services rendered to the community and for the cause of education as a teachers of outstanding merit.  CBSE has awarded this award in recognition and appreciation of making a significant contribution to the field of education and imparting positively upon the lives of students.</p>
            <p>Mr. Sofat is teaching Mathematics with passion for more than two decades.Mr. Sofat is a member in Development Committee of NCERT Mathematics books.  He is also associated as a resource person in development of Mathematics kit and audio video material.  He as a subject expert is associated with NAS (National Achievement Survey) of survey division and development of digital content for MOOCS at SWAYAM Portal of MHRD.</p>
            <p><b>Chief Guest-</b>Shri Ramesh Pokhriyal “Nishank”<br />
              Hon’ble Minister of Human Resource Development<br />
              Government of India</p>
            <p><b>Distinguished Guest-</b>Shri Sanjay Dhotre<br />
              Hon’ble Minister of State (MHRD) Government of India
            </p>
            <p>at PravasiBharatiya Kendra, Chanakyapuri, New Delhi-21<br />

              Ms. Anita Karwal, Chairperson CBSE & Mr. Anurag Tripathi, Secretary CBSE also presided over the ceremony.
            </p>

          </div></div>
        <div className="clr25"></div>
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 width-100">
          <LazyLoad>  <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/Special_Honors_1.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 width-100">
          <LazyLoad>    <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/innerbg/Special_Honors_1.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SpecialHonors