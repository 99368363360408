import { useEffect, useState } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import { getNewsInner } from "../Service/Api";
import { getHighlightInner } from "../Service/Api";
import Header from "../Component/Header";
import Footer from "../Component/Footer";   
const News = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'News - AFGJI';  
    }, []);
    
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [callApi, setCallAPI] = useState("NewsCall");
    const [monthOptions, setMonthOptions] = useState(["All", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Initialize selected year as current year
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setSelectedYear(currentYear.toString());
    }, []);

    // Fetch data when callApi changes
    useEffect(() => {
        const fetchData = async () => {
            let galleryData = [];

            try {
                // Conditional API call
                if (callApi === "NewsCall") {
                    // Await the API response, then filter the result
                    const newsData = await getNewsInner();
                    galleryData = newsData.filter((item) => item.category === "News" || item.category === "Both Sections");
                    console.log(galleryData); // Log the filtered data
                } else if (callApi === "HighlightCall") {
                    // Await the API response, then filter the result
                    const highlightData = await getHighlightInner();
                    galleryData = highlightData.filter((item) => item.category === "Highlight"|| item.category === "Both Sections");
                    console.log(galleryData); // Log the filtered data
                }

                // Ensure galleryData is an array
                if (!Array.isArray(galleryData)) {
                    console.error("Data received is not an array:", galleryData);
                    galleryData = [];
                }

                console.log(galleryData);
                setData(galleryData);

                // Get unique years
                const uniqueYears = Array.from(
                    new Set(galleryData.map((item) => {
                        if (item.fromDate) {
                            const year = new Date(item.fromDate).getFullYear();
                            return year;
                        }
                        return null;
                    }).filter(year => year !== null)) // Filter out any null values
                );

                const uniqueYearRanges = uniqueYears.map(year => `${year}`);
                uniqueYearRanges.sort((a, b) => b - a);
                setYearRanges(uniqueYearRanges);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [callApi]);

    // Filter data whenever selectedYear, selectedMonth, searchQuery, or data changes
    useEffect(() => {
        let filteredData = Array.isArray(data) ? [...data] : []; // Ensure data is an array

        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const year = new Date(item.fromDate).getFullYear();
                    return parseInt(selectedYear) === year;
                }
                return false;
            });
        }

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const month = new Date(item.fromDate).getMonth();
                    return monthNames[month] === selectedMonth;
                }
                return false;
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort by date in descending order
        filteredData.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const handleApiChange = (e) => {
        const value = e.target.value;
        if (value === "Three") {
            setCallAPI("NewsCall");
        } else {
            setCallAPI("HighlightCall");
        }
    };

    const emptyArray = [
      {title:"News Title", description:"Stay Tuned For More Updates", day:"DD", month:"MM"},
      {title:"News Title", description:"Stay Tuned For More Updates", day:"DD", month:"MM"}
    ];

    return (
        <>
            <Header /> 
            <div className="container-fluid innerbg">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6">
                            <h2 className="innerhead">News / Highlights</h2>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6">
                            <select id="ddlYear" onChange={handleApiChange}>
                                <option value="Three">News / Notices</option>
                                <option value="Two">Highlights</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="body_height">
                <div className="container">
                    <div className="row tabs-dynamic">
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="month-selection">
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2023 showYear">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) => {
                                const date = new Date(item.fromDate);
                                const day = date.getDate();
                                const month = monthNames[date.getMonth()];
                                return (
                                    <div className="col-md-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 newsCount" key={index}>
                                        <div className="news-blk">
                                            <div className="news-head">
                                                <h2>{day} <span>{month}</span></h2>
                                            </div>
                                            <div className="news-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                            {item.attachments?.length > 0 && (
                                                <div className="attachment">
                                                    <Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank">
                                                        <i className="bi bi-paperclip"></i>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            emptyArray.map((item, index) => (
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 newsCount" key={index}>
                                    <div className="news-blk">
                                        <div className="news-head">
                                            <h2>{item.day} <span>{item.month}</span></h2>
                                        </div>
                                        <div className="news-content">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="attachment">
                                            <Link to="#" target="_blank">
                                                <i className="bi bi-paperclip"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Footer /> 
        </>
    );
};

export default News;
