
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb";  
const Swarnima = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Swarnima" breadcrumbName="Swarnima" />
          
<div className="container body_height">
  <div className="row">

 

<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://online.anyflip.com/rojof/lnkh/mobile/index.html" target="_blank"> Swarnima (2022-23)</Link> </div>
</div>
<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://anyflip.com/zkpml/izlb/" target="_blank"> Swarnima (2021-22)</Link> </div>
</div>
<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://anyflip.com/scgmq/tfuo/" target="_blank"> Swarnima (2020-21) </Link> </div>
</div>
<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://online.anyflip.com/scgmq/nsbk/mobile/index.html" target="_blank"> Swarnima (2019-20)</Link> </div>
</div>
  </div>
</div>

                <Footer />
        </>
    )
}

export default Swarnima