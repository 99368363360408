
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
const Ourschool = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="About Our School" breadcrumbName="About Our School" />
             
<div className="container body_height">
  <div className="row">
    <div className="col-sm-12 col-md-8 col-lg-8">
      <p>The idea of setting up Air Force Golden Jubilee Institute was conceived in 1982, the Golden Jubilee year of the Indian Air Force. It was thought by the then Chief of Air Staff, Air Chief Marshal Dilbagh Singh PVSM AVSM VM ADC that a special institution should be set up for children with special needs to commemorate 50 years of the Indian Air Force.</p>
      <p>Subsequently, it was realised that it would be a good idea to integrate the learning needs of the Special children with those of general children and create a school which facilitates learning in a conducive atmosphere. Air Cmde BK Nigam VSM (Retd) Founder Principal gave a proposal in this regard and put his heart and soul into this project to get funds sanctioned and set up this Institute. It was thought that this Institute would also help the children to achieve greater sensitivity towards the needs and problems of children with special needs and constant interaction between them would enable them to learn the values of tolerance and compassion whereby the school would be able to create dynamic leaders and missionaries, who would help in global development. An integrated system of education would also help to rehabilitate the Special children in the society.</p>
      <p>The school is one of the trinity of the co- educational schools run by the Indian Air Force Education and Cultural Society and is located at Subroto Park in New Delhi in an area of 7.9 acres. At present, there are 2130 students in the general wing and 70 students in Special Wing. who receive education in our school, in accordance with NEP 2020.</p>
      <p>The school's aim is to train its students to brave the challenges in the dynamic world by providing a forum where every child gets the opportunity to develop his inherent qualities and nurture his potential. All the curricular and co-curricular activities try to keep a balance between academic excellence and the individual interest of the child, as we believe that education is not preparation of life but it is life itself. The Institute also aims to integrate the learners with the differently abled children so that they can learn and grow by mutual co-operation.</p>
      <p>Air Force Golden Jubilee Institute (AFGJI) is a pioneer in the realm of inclusive education and when it began in 1986, it was the first of its kind in Asia. From 30 Special students, the school in now 2200 strong.</p>
    </div>
    <div className="col-sm-12 col-md-4 col-lg-4 hidden-sm hidden-xs"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/our-school1.jpg" className="img-responsive center-block mb-4" alt="AFGJI" /></LazyLoad> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/our-school2.jpg" className="img-responsive center-block mb-4" alt="AFGJI" /></LazyLoad> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/our-school3.jpg" className="img-responsive center-block mb-4" alt="AFGJI" /></LazyLoad> </div>
    
  </div>
</div>
                <Footer />
        </>
    )
}

export default Ourschool