import { useState } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";  

const ResultAnalysis = () => {
    // Manage active tab state
    const [activeTab, setActiveTab] = useState("csw");

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Result Analysis Class X & XII" breadcrumbName="Result Analysis Class X & XII" />
         
            <div className="container body_height">
                <div className="row">
                    <div className="col-md-3 leftmenu">
                        <div className="leftmenubg">
                            <p className="menuhead">Important Links</p>
                        </div>
                        <div id="events">
                            <ul className="nav nav-tabs" role="tablist"> 
                                <li role="presentation" className={activeTab === "csw" ? "active" : ""} >
                                    <a
                                        href="#csw"
                                        onClick={() => setActiveTab("csw")}
                                    >
                                        Result Analysis Class X
                                    </a>
                                </li>
                                <li
                                    role="presentation"
                                    className={activeTab === "cpw" ? "active" : ""}
                                >
                                    <a
                                        href="#cpw"
                                        onClick={() => setActiveTab("cpw")}
                                    >
                                        Result Analysis Class XII
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content"> 
                            <div
                                role="tabpanel"
                                className={`tab-pane ${activeTab === "csw" ? "active" : ""}`}
                                id="csw"
                            >
                                <div className="col-md-12">
                                    <h3 className="sub_head">Result Analysis Class X</h3>
                                    <object
                                        data="https://webapi.entab.info/api/image/AFGJI/public/pdf/X_FINAL.pdf"
                                        type="application/pdf"
                                        width="100%"
                                        height="810"
                                        style={{ border: "2px solid #c1c1c1" }}
                                    ></object>
                                </div>
                            </div>
                            <div
                                role="tabpanel"
                                className={`tab-pane ${activeTab === "cpw" ? "active" : ""}`}
                                id="cpw"
                            >
                                <div className="col-md-12">
                                    <h3 className="sub_head">Result Analysis Class XII</h3>
                                    <object
                                        data="https://webapi.entab.info/api/image/AFGJI/public/pdf/XII_FINAL.pdf"
                                        type="application/pdf"
                                        width="100%"
                                        height="810"
                                        style={{ border: "2px solid #c1c1c1" }}
                                    ></object>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ResultAnalysis;
