import React from 'react'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import News from '../Component/News'
import Circular from '../Component/Circular'
import Popup from '../Component/Popup'
import Events from '../Component/Events'
import { Carousel } from 'react-bootstrap'
import OnlineRegistration from '../Component/OnlineRegistration'
import TopperX from '../Component/TopperX'
import TopperXII from '../Component/TopperXII'
import Gallery from '../Component/Gallery'
import {Link} from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import Acheivement from '../Component/Acheivement'
import VideoPopUp from '../Component/VideoPopUp'

const Home = () => {
  return (
    <>
    <VideoPopUp />
      <div className="erp_links"> <Link to="http://www.afgjicampuscare.in/" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Campulogo.png" alt="AFGJI" className="img-responsive" /></LazyLoad></Link> </div>

      <div className="OnlineFee2">
        <Link to="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=4764384" target="_blank">&nbsp; Online fee payment: SBI e collect</Link>
      </div>
      <Popup />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-9 no-pad slidernotify">
            <div id="myCarousel" className="carousel slide" data-ride="carousel">

              <div className="carousel-inner">
                <Carousel>
                  
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-14.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-15.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-16.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-17.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-0.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-1.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-2.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-3.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-4.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-5.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-6.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-7.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-8.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-9.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-10.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-11.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-12.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/slide-13.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                </Carousel>
              </div>
              
              </div> 
            <OnlineRegistration />
          
            <div className="OnlineR">
              <span className="bounce1">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Corrigendum.pdf" target="_blank" className="parantlogo "> Corrigendum  Notification   </Link>

              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 no-pad news_area">
            <div className="col-md-12">
              <h4>News/Notice <Link to="news"><i className="bi bi-arrow-right"></i></Link></h4>
              <hr />
              <marquee scrollamount="3" scrolldelay="30" direction="up" behavior="scroll" align="left" onmouseover="stop();" onmouseout="start();" className="news_mscrool">
 
                <News />
 
              </marquee>
            </div>
            <div className="col-md-12 circularbox">
              <h4>Circular<Link to="circular"><i className="bi bi-arrow-right"></i></Link></h4>
              <div id="circularbox" className="carousel slide" data-ride="carousel">

                <div className="carousel-inner">
                  <Circular />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 graybg">
            <div className="pad_box_left">
              <div className="col-md-12">
                <h1 className="main_head">About <span>AFGJI</span></h1>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/about.jpg" className="img-responsive  center-block" alt="AFGJI" /></LazyLoad></div>
              <div className="col-xs-8 col-md-8 col-md-8">
                <p className="about-tran">The idea of setting up Air Force Golden Jubilee Institute was conceived in 1982, the Golden Jubilee year of the Indian Air Force. It was thought by the then Chief of Air Staff, Air Chief Marshal Dilbagh Singh PVSM AVSM VM ADC that a special institution should be set up for children with special needs to commemorate 50 years of the Indian Air Force. Subsequently, it was realised that it would be a good idea to integrate the learning needs of the Special children with those of general children and create a school which facilitates learning in a conducive atmosphere. Air Cmde BK Nigam VSM (Retd) Founder Principal gave a proposal in this regard and put his heart and soul into this project to get funds sanctioned and set up this Institute. </p>
                <div className="clr10"></div>
                <h5><Link to="/ourschool" className="more" role="button">More About AFGJI</Link></h5>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bluebg">
            <div className="pad_box_right">
              <div className="col-md-12">
                <h2 className="main_head white">Principal's Desk</h2>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/principal-big.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad>


              </div>
              <div className="col-xs-8 col-sm-8 col-md-8">
                <p className="about-tran">

                  Air Force Golden Jubilee Institute is managed by the IAF Educational and Cultural Society.  It is a pioneer in the field of Inclusive Education since 1986.  Its objective is to impart the highest quality of education to all its learners, in order to create an equitable, inclusive and plural society as envisaged by our Constitution.  The reforms in school education have to be aligned with the aspirations of a ‘Viksit Bharat’ and the school is committed to create a learning environment which cultivates intellect and fortifies character.  The emphasis is on inculcating 21st century skills – critical thinking, problem solving, creativity, leadership, social skills etc.  We, at AFGJI are committed to train our students to blossom into sincere and compassionate citizens, who will be conscious of the needs of the society and the environment.  They will also have the technological prowess to suits the needs of the ever evolving global scenario.</p>


                <div className="clr10"></div>
                <h5><Link to="/message_desk" className="more" role="button">Read Full Message</Link></h5>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-8 event-section">
            <h2 className="main_head text-center">Upcoming <span>Events</span></h2>
            <div className="heading-border-line"></div>
            <div className="col-md-12">
            <Events />
          </div>
          <center>
            <Link to="/Events" className="btn btn-info btncenter" role="button"> More Events</Link>
          </center>
          </div>
          <div className="col-md-4 event-section">
            <h2 className="main_head text-center">Our <span>Achievements</span></h2>
            <div className="heading-border-line"></div>
            <div className="col-md-12">
            <Acheivement />
          </div>
          <center>
            <Link to="/Acheivements" className="btn btn-info btncenter" role="button"> View More</Link>
          </center>
          </div>
         
        </div>
      </div>
      <section id="testimonial" className="bg_cover pt-115 pb-115 topperbg" data-overlay="8">
        <div className="container">
          <div className="row testimonial-slide mt-70 slick-initialized slick-slider slick-dotted">
            <div className="col-sm-12 col-md-7">
              <div className="row">
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space">
                  <Link to="/activities-senior-wing"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/Activity_Senior.jpg" className="img-responsive center-block roundimg" alt="AFGJI" /></LazyLoad>
                    <div className="overlay-box">
                      <div className="text">Activity Senior</div>
                    </div>
                  </Link>
                </div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space">
                  <Link to="/activities-primary-wing"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/Activity_Junior.jpg" className="img-responsive center-block roundimg" alt="AFGJI" /></LazyLoad>
                    <div className="overlay-box">
                      <div className="text">Activity Junior </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xs-4 col-sm-3 col-md-3 galoverlay-box space"> <Link to="/infrastructure_senior_wing"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/Sports_Senior.jpg" className="img-responsive center-block roundimg" alt="AFGJI" /></LazyLoad>
                  <div className="overlay-box">
                    <div className="text">Sports Senior</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/infrastructure_primary_wing"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/Sports_Junior.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" /></LazyLoad>
                  <div className="overlay-box">
                    <div className="text">Sports Junior</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/infrastructure_special_wing"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/media.jpg" className="img-responsive center-block roundimg" alt="AFGJI" /></LazyLoad>
                  <div className="overlay-box">
                    <div className="text">Special Wing</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/special_honors"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/Special_Honors.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" /></LazyLoad>
                  <div className="overlay-box">
                    <div className="text">Special Honors</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/media_gallery"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/gallery/1.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" /></LazyLoad>
                  <div className="overlay-box">
                    <div className="text">Media</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/memories"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/box/memories.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" /></LazyLoad>
                  <div className="overlay-box">
                    <div className="text">Memories</div>
                  </div>
                </Link></div>
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-sm-12">
              <div className="row">
                <div id="10topper" className="carousel slide" data-ride="carousel">

                  <div className="carousel-inner">
                    <TopperX />

                  </div>
                </div>
              </div>
              <div className="clr20"></div>
              <div className="row">
                <div id="12topper" className="carousel slide" data-ride="carousel">

                  <div className="carousel-inner">
                    <TopperXII />
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container tb-pad-20">
  <div className="row">
    <div className="col-md-12">
      <h2 className="main_head">Photo <span>Gallery</span> <Link to="/Gallery"><i className="bi bi-arrow-right"></i></Link></h2>
    </div>
  </div>
  
  <div className="row">
    <div className='col-md-12'>
      <Gallery />
      </div>
      </div>
     
    
        </div>
      <Footer />
    </>
  )
}

export default Home