import React, { useEffect } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
const Mediagallery = () => {
 
  const [index, setIndex] = React.useState(-1);
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Media Gallery" breadcrumbName="Media Gallery" />
           
  <div className="container body_height media_gal_sec">
    <div className="row">
             
          <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/09-Oct-2024.jpeg" onClick={() => setIndex(index)}>
      <LazyLoad>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/09-Oct-2024.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)}   /></LazyLoad>
            </a> 
            <h4 className="media-head">  TOI dated 09 Oct 2024 regarding AFGJI students showcase Goan spirit at Subroto Park </h4> 
      </div> 
  </div> 
  <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
      <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /></LazyLoad>
            </a> 
            <h4 className="media-head">TOI dt 01 Oct 2024 regarding Mrs Alka Singh, Principal's article on Bapu is an Apostle of Peace  </h4> 
      </div> 
  </div> 
  <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad>
      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
       <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/30-Sep-2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> 
            </LazyLoad>
            <h4 className="media-head"> TOI dated 30 Sep 2024 regarding position of AFGJI in South West Delhi Leaders according to Times School Survey 2024 </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/20-Sep-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head"> TOI dated 20 Sep 2024 regarding  AirForce GJI holds TEDx 2024 </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/18-Sep-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head"> TOI dt 18 Sep 2024 regarding painting of Aditi Gupta of class XII </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/17-Sep-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head">HT dated 17 Sep 2024 regarding AFGJI organises TEDx Talks  </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Cfore-Ranking-aug24.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head"> Cfore Ranking of AFGJI published in Brainfeed Magazine of month Aug 2024 </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/27-Aug-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head"> TOI dated 27 Aug 2024 regarding AFGJI hosts Confluence - 2024 </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/14-Aug-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head"> TOI dt 14 Aug 2024 regarding Mrs Alka Singh, Principal's article on It revolution transformed India </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/09-Aug-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head"> TOI dated 09 Aug 2024 regarding Tree Planation Drive by Primary Wing of AFGJI </h4> 
      </div> 
  </div> 
    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
      <div className="column1">
      <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
              <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/25-Jul-2024.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} />
            </a> </LazyLoad>
            <h4 className="media-head">TOI dated 25 Jul 2024 regarding AFGJI conducts Investiture Ceremony with enthusiasm  </h4> 
      </div> 
  </div>  
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> 
      <LazyLoad>
      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/01-Oct-2024.jpg" onClick={() => setIndex(index)}>
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/ResultClassXII24may2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a> </LazyLoad><h4 className="media-head"> CBSE Result Class XII published in TOI, Delhi Times dated 24 May 2024 </h4> </div> </div> 
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/IndianSports7may2024.png"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/IndianSports7may2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
</a> </LazyLoad><h4 className="media-head">Report on Indian Sports Meet for Specially-abled Students published in TOI, Delhi Times dated 07 May 2024</h4> </div> </div> 
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AlkaSingh14feb2024.png"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AlkaSingh14feb2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Article of Ms Alka Singh, HOD English regarding Build Bridges Instead of walls published in TOI Student's Edition dated 14 Feb 2024 </h4> </div> </div> 
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AlkaSingh12feb2024.png"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AlkaSingh12feb2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Article of Ms Alka Singh, HOD English regarding Celebration of Learning published in TOI Student's Edition dated 12 FEb 2024</h4> </div> </div> 
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AditiGupta-12feb2024.png"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AditiGupta-12feb2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Article of Aditi Gupta class XI A regarding Humanity Lies in Love published in TOI Student's Edition dated 12 Feb 2024</h4> </div> </div> 
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/RepublicDay25jan2024.png"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/RepublicDay25jan2024.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Principal's Quotation published in TOI student's Edition on 25 Jan 2024 on the occasion of Republic Day </h4> </div> </div>
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/28-Dec-2023.jpg"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/28-Dec-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Sports Day for specially abled students report published in Hindustan Times student's edition on 28 Dec 2023</h4> </div> </div>
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/14-Dec-2023.jpg"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/14-Dec-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a> </LazyLoad><h4 className="media-head">Article of Manasvi Bhadoria of class IX D, published in TOI Student's edition on 14 Dec 23</h4> </div> </div>
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/13-Dec-2023.jpg"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/13-Dec-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Adarsh Kumar of AFGJI won first prize in event organised by Times NIE on 02 Dec - published in TOI dated 13 Dec 23.</h4> </div> </div>
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/7-Dec-2023.jpg"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/7-Dec-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Felicitation Ceremony Report in HT Student's edition published on 07 Dec 23</h4> </div> </div>
<div className="col-xs-6 col-sm-4 col-md-4 col-lg-4"> <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/05-Dec-2023.jpg"><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/05-Dec-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad> <h4 className="media-head">Mrs Alka Singh's write up published in TOI Student's edition published on 05 Dec 2023</h4> </div> </div>




    <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/28-Sep-2023.jpg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/28-Sep-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Times School Survey 2023 published in Times of India dated 28 Sep  2923</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/27-Sep-2023.jpg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/27-Sep-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Principal's quotation published in TOI Student's edition  on 27 Sep 2023 on the occasion of Gandhi Jayanti</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/20-Sep-23.jpeg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/20-Sep-23.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Report on TEDx Air Force GJI Youth event published in HT Student's Edition on 20 Sep 23</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/19-Sep-2023.jpg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/19-Sep-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Report on TEDx Air Force GJI Youth event published in TOI on 19 Sep 23</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/05-Sep-2023.jpg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/05-Sep-2023.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Teacher's Day in TOI Student's edition on 05 Sep 2023 </h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI Student's Edition dt-23-08-23.png"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI Student's Edition dt-23-08-23.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Investiture ceremony report - TOI Student's Edition dt 23 Aug 23</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT Student's edition on 23-08-23.jpeg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT Student's edition on 23-08-23.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Investiture ceremony Report - HT Student's edition on 23 Aug 23</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Confluence  Report - TOI dt 09 Aug 23.jpg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Confluence  Report - TOI dt 09 Aug 23.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Confluence  Report - TOI dt 09 Aug 23</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Confluence Report - HT - 01 Aug 23.jpeg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Confluence Report - HT - 01 Aug 23.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Confluence Report - HT - 01 Aug 23</h4>
        </div>
      </div>
     <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Class XII Board Result - TOI dt 14 Jun 23.png"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Class XII Board Result - TOI dt 14 Jun 23.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Class XII Board Result - TOI dt 14 Jun 23</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Kristlee-class-7B.png"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Kristlee-class-7B.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> 
        </a></LazyLoad>
        <h4 className="media-head">Painting  of Kristlee of class 7 B published in HT Student's edition on 03 May 2023</h4>
        </div>
      </div>


      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> 
        <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/CBSE-Class-12-Results.jpg"> 
        <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/CBSE-Class-12-Results.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad>
          <h4 className="media-head">Class XII results of the school published in HT Student's edition on 19 May 23</h4>
        </div>
      </div>




      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Devanshish-singh-TOI-Student.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Devanshish-singh-TOI-Student.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> 2 Beautiful paintings by our super talented Devanshish  Kumar Singh of Class IX published in TOI Student's edition on 07 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-student-03-Feb-2023.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-student-03-Feb-2023.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article in HT Student's edition on 03 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/XII-farewell-TOI-Student-21-feb.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/XII-farewell-TOI-Student-21-feb.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Class XII farewell report published in TOI Student's eidtion on 21 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Farewell-teachers-Hindustan-Times-06-apr.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Farewell-teachers-Hindustan-Times-06-apr.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Farewell of teachers and release of magazine in Hindustan Times  on 06 Apr 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Lohita-poem-TOI-Student-14-feb.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Lohita-poem-TOI-Student-14-feb.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Lohita's poem published in TOI Student's edition on 14 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Painting-Akanksha-Chaudhary-XII-HT-Student.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Painting-Akanksha-Chaudhary-XII-HT-Student.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting by Akanksha Chaudhary of Class XII in HT Student's edition on 15 Mar 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Poem-Lohitha-Sakku-HT-Student-edition.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Poem-Lohitha-Sakku-HT-Student-edition.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Poem by Lohitha Sakku published in HT Student's edition on 16 Mar 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Prize-distribution-ceremony-HT-Student-edition.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Prize-distribution-ceremony-HT-Student-edition.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Prize distribution ceremony report published in HT Student's edition on 17 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Reburbished-basket-ball-HT-student-edition.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Reburbished-basket-ball-HT-student-edition.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Reburbished basket ball court  - Report published in HT student's edition on 28 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Spl-Wg-Fashion-Fiesta-TOI-Student.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Spl-Wg-Fashion-Fiesta-TOI-Student.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive" onClick={() => setIndex(index)} /> </a></LazyLoad>
          <h4 className="media-head">Spl Wg report on Fashion Fiesta published in TOI Student's edition on 13 Mar 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Alka-Singh-TOI-Student-2023.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Alka-Singh-TOI-Student-2023.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Write up of Mrs Alka Singh on iconic women published in TOI Student's edition on 07 Feb 2023</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Painting-Aditi-Gupta-X-HT-Student-edition.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Painting-Aditi-Gupta-X-HT-Student-edition.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Aditi Gupta, Class X A published in HT Student's edition dated 14 Dec 2022</h4>
        </div>
      </div>
				
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Alka-singh-HOD-English.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Alka-singh-HOD-English.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Alka Singh, HOD English, AFGJI, Subroto Park, New Delhi</h4>
        </div>
      </div>
		
		   <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-30-nov-2022.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-30-nov-2022.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Ahana Sharma, Class VI- Painting published in HT Student's edition on 30/11/2022</h4>
        </div>
      </div>
		
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-17-oct-2022.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-17-oct-2022.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Book Meet published in HT student's edition on 17 Oct 22</h4>
        </div>
      </div>
		
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="column1">
            <div className="col-md-6 col-sm-6 col-xs-6"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/SCAN_001.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/SCAN_001.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad> </div>
            <div className="col-md-6 col-sm-6 col-xs-6"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/SCAN_002.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/SCAN_002.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad> </div>
            <h4 className="media-head"> Education World, a  human development magazine published the EW School Rankings for the year 2022-23 in its October 2022 edition. Our school,                     Air Force Golden Jubilee Institute secured the 4th position in the India’s top central government day schools category. It also secured the 3rd position in the state rankings in Delhi NCR category.  To compile EWISR 2022-23, 14,000 sample respondents – educationalists, principals, teachers,  parents and senior school students  were interviewed by the  field researchers of the Centre for Forecasting & Research Pvt Ltd, C fore, Delhi,  to rate the country’s most reputed schools on a scale of 1-100 on 12-15 parameters of school education excellence. The annual EWISR is the world’s largest schools ranking survey.  
              The Annual Education World India School Rankings (EWISR) survey,  rates and ranks 3,500-,4000 of India’s Best schools.</h4>
          </div>
        </div>
      </div>
      
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-on-13-oct-2022.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-on-13-oct-2022.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Book Meet report published in TOI Student's edition on 13 Oct 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-07-Oct-2022.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-07-Oct-2022.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Painting of Aditi Gupta, Class X A published in HT Student's edition on 07 Oct 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-on-30-Sep-2022.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-on-30-Sep-2022.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Principal's quote in TOI Student's edition on 30 SEP 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-30-Sep-2022.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-30-Sep-2022.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Investiture ceremony report of the school in HT Student's edition on 30 SEP 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-School-Supplement-Paper-on-22-Sep-2022.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-School-Supplement-Paper-on-22-Sep-2022.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> 27 Sep 22 - TOI - Times School Supplement paper - Ranking of AFGJI on 22 SEP 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-on-14-Sep-2022.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-on-14-Sep-2022.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Akshata Solanki of Class 11 A in TOI Student's edition on 14 SEP 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-21-Sep-2022.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-21-Sep-2022.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Sanjana Behura of class 12 D painting in HT Student's edition on 21 Sep 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-08-Sep-2022.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-08-Sep-2022.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Report on Futurz Club of AFGJI hosts annual iner-school IT event in HT Student's edition on 08 Sep 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-08-Aug-22.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-on-08-Aug-22.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article on AFGJI hoste 12th Inter School Events 2022 in HT Student's Edition on 08 Aug 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-02-04-2022.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-02-04-2022.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Class 12 result in HT Student's Edition on 02 Aug 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-01-04-2022.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-Student's-Edition-01-04-2022.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Class 12 result in TOI Student's Edition on 01 Aug 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-17-May-22.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-17-May-22.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Report on AFGJI releases magazine for class 11 students in HT Student's edition on 17 May 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-04-May-22.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student's-Edition-04-May-22.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Class 12 Farewell report published in HT Student's Edition on 04 May 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Dr-Vibhore-Singhal-04-03-22.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Dr-Vibhore-Singhal-04-03-22.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Report of online interview conducted by our school on 27 Mar 2022 with our alumnus Dr Vibhore Singhal published in HT Student's edition on 04 Apr 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Interview-05-04-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Interview-05-04-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Interview with alumnus report published in TOI student's edition on 05 Apr 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Principal-article-01-04-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Principal-article-01-04-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Principal madam's article on Page 3 of TOI Student's edition published on 01 Apr 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Angaja-30-03-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Angaja-30-03-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Angaja XII C - paintings published in TOI Student's edition on 30 Mar 2021</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Aayushi-Singh-29-03-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Aayushi-Singh-29-03-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Aayushi Singh Class XIIA, paintings published in TOI Student's edition on 29 Mar 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Vriddhi-Bhardwaj-15-03-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Vriddhi-Bhardwaj-15-03-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Vriddhi Bhardwaj, Class XI B, article in HT Student's edition on 15 Mar 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Sneha-Roy-Chowdhury-21-02-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Sneha-Roy-Chowdhury-21-02-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Sneha Roy Chowdhury, Class XII A - poem published in TOI Student's edition on 21 Feb 22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Vishal-Bhardwaj-18-02-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Vishal-Bhardwaj-18-02-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Vishal Bhardwaj, Class XII - Painting published in TOI Student's edition on 18 Feb 2022</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Mrs-Alka-Singh-17-02-22.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Mrs-Alka-Singh-17-02-22.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Mrs Alka Singh, HOD-English - Article in HT Student's edition 17 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akasksha-Chaudhary-15-02-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akasksha-Chaudhary-15-02-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Akasksha Chaudhary, Class XI B - Poen published in TOI Student's edition 15 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Divya-yadav-10-02-2022.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Divya-yadav-10-02-2022.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Diya Yadav, Class XII A - painting published in HT Student's edition dt 09 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akshika-Dhankar-10-02-2022.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akshika-Dhankar-10-02-2022.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Akshika Dhankar, XII A Poem published in TOI Student's edition on 10 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Alka-Singh-07-02-2022.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Alka-Singh-07-02-2022.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article by Mrs Alka Singh, HOD-English published in TOI Student's edition on 07 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Tanya-Pandey-04-02-2022.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Tanya-Pandey-04-02-2022.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head"> Paintings of Tanya Pandey, Class XI B in TOI Student's edition published on 04 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Diya-Yadav-02-02-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Diya-Yadav-02-02-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Diya Yadav, Class XII A - painting published in HT Student's edition on 02 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Janvee-Kumari-01-02-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Janvee-Kumari-01-02-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Janvee Kumari, XII A - painting published in TOI Student's edition on 01 Feb'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Article-Mrs-Alka-Singh-25-1-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Article-Mrs-Alka-Singh-25-1-22-thumb.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article by Mrs Alks Singh, published in HT Student's edition on 25 Jan'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Painting-Aayushi-Singh-Class-XII-A-TOI-25-1-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Painting-Aayushi-Singh-Class-XII-A-TOI-25-1-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Aayushi Singh, Class XII A published in TOI Student's edition on 25 Jan'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AAYUSHI-SINGH-24-01-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/AAYUSHI-SINGH-24-01-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Aayushi Singh, Class XII A published in TOI Student's edition on 24 Jan'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/NIKHIL-BHUSHAN-20-1-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/NIKHIL-BHUSHAN-20-1-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Nikhil Bhushan, Class XII A published in TOI Student's edition on 20 Jan'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Khristia-Laiphrakpam-XIB-17-1-22.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Khristia-Laiphrakpam-XIB-17-1-22.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Khristia Laipharkpan, Class XI B published in TOI Student's edition on 17 Jan'22</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Mrs-Alka-Singh-31-12-21.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Mrs-Alka-Singh-31-12-21.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article by  Mrs Alka Singh (HOD-English) published in TOI Student's edition on 31 Dec'21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akanksha-Chaudhary-06-01- 2022.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akanksha-Chaudhary-06-01- 2022.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article by Akanksha Chaudhary, Class XII B in TOI STUDENT'S EDITION dt 06 Jan'22</h4>
        </div>
      </div>
    </div>
    <div className="clr20"></div>
    <div className="row">
      <div className="col-md-12">
        <p>1962, Major Shaitan Singh & 114 of 120 soldiers bravely laid down their lives in the historic #RezangLa Battle...60 yrs later, a #WarMemorial by #IndianArmy has been inaugrated and we are proud that it was inaugrated with an AFGJI Connection..
          AFGJI is our school - Air Force Golden Jubilee Institute, New Delhi and .. Chushul Brigade Commander, Brigadier Munesh C Tamang ,VSM is an AFGJI Alumni ( Batch of 1991) and Vice- Captain of 1991....Munesh is from Assam Regiment and he commanded the same battalion, that he was inducted in, its an honour that not many officers get and is usually given to most deserving candidates...we wish Munesh all the very best in his future efforts and thank him for his service to the nation and for making all of us at #AFGJI super proud of him..
          The Rezang La memorial is near Pangong Lake so pls do take out time to visit it.<br />
          #JaiHind 🇮🇳🇮🇳<br />
          #afgjiforever</p>
        <p className="link_color"><Link to="https://www.youtube.com/watch?v=B3-PWzMmXnw" target="_blank"><b>https://www.youtube.com/watch?v=B3-PWzMmXnw</b></Link></p>
      </div>
    </div>
    <div className="clr20"></div>
    <div className="row">
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akanksha-Chaudhary.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Akanksha-Chaudhary.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article of Akanksha Chaudhary of Class 11</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Gauris-Nair-15-12-21.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Gauris-Nair-15-12-21.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article of Gauri S Nair of class 9 in The Times of India dt 15 Dec 21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Shaurya-Karmakar-16-12-21.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Shaurya-Karmakar-16-12-21.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article  of Shaurya Karmakar of class 8 D in The Times of India dt 16 Dec 21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Article-of-Akanksha-Chaudhary-Class-XI.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Article-of-Akanksha-Chaudhary-Class-XI.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Akanksha Chaudhary of Class XI published in "The Times of India" dated 09 Dec 21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Japsirat-Kaur.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Japsirat-Kaur.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Japsirat Kaur, Class  4 in Hindustan Times, Student's  Edition dated 08 Dec 21</h4>
        </div>
      </div>
    </div>
    <div className="clr20"></div>
    <div className="row">
      <div className="col-md-12">
        <p><b>EDUCATION WORLD INDIA SCHOOL RANKINGS  (EWISR) 2021-22 PUBLISHED IN  MAGAZINE - EDUCATION WORLD</b></p>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/EDUCATION-WORD_1.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/EDUCATION-WORD_1.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad> </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/EDUCATION-WORD_3.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/EDUCATION-WORD_3.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad> </div>
      </div>
      <div className="col-md-12">
        <p className="media-head">To compile EWISR 2021-22, 11,458 sample respondents – educationalists, principals, teachers, SECA (socio-economic category A) parents and senior school students – in 28 cities  countrywide were interviewed by 118 C fore field researchers over four months.  The annual EWISR is the world’s largest schools ranking survey.</p>
      </div>
    </div>
    <div className="clr20"></div>
    <div className="row">
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Khristia-Laiphrakpam.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Khristia-Laiphrakpam.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting  Of Khristia Laiphrakpam,  Class XI 15/10/21 TOI</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Soumil-15-10-21.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Soumil-15-10-21.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article Of Soumil Of Class XII 15/10/21 TOI</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-15-10-21.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI-15-10-21.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Article Of Mrs Alka Singh 15/10/21 TOI</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Student-painting-06-10-21.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Student-painting-06-10-21.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Student's Painting Published on 06/10/2021 In Hindustan Times </h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Ranking-TOI-Survey-27092021.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Ranking-TOI-Survey-27092021.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Ranking of AFGJI TOI Survey dt 27/09/2021</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student-edition-27-09-21.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT-Student-edition-27-09-21.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT Student's Edition. 27/09/2021 </h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Student-Corner.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Student-Corner.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Student Corner </h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Commerce-Fest-Coverage-12092021.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Commerce-Fest-Coverage-12092021.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI - Commerce Fest Coverage on 12/09/2021 </h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Anishika-Sinha.PNG"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Anishika-Sinha.PNG" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">Painting of Anishika Sinha in HT Students's Edition on 08/09/2021</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Z-REPORT-23-08-21.jpg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Z-REPORT-23-08-21.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">ZENITH REPORT IN TOI  DT 23/08/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/Images/media/24082021045435-uxz.pdf" target="_blank"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/media-img.jpg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </Link>
          <h4 className="media-head">HT  24/08/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT  120421.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT  120421.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT  12/04/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT  painting comeption result.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT  painting comeption result.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT Painting Comeption Result</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 010621.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 010621.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 01/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 040821.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 040821.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 04/08/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 050821 nehal.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 050821 nehal.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 05/08/21 Nehal</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 060521.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 060521.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 06/05/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 080621.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 080621.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 08/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 120821.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 120821.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 12/08/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 160821.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 160821.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">-</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 231221.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 231221.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 23/12/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 280721.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 280721.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 28/07/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 300421.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 300421.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 30/04/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 02062021.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 02062021.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 02/06/2021</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 05072021.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT 05072021.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 05/07/2021</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT220721.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT220721.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 22/07/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT21072021.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/HT21072021.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">HT 21/07/2021</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TIO 080621.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TIO 080621.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TIO 08/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TIO280621.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TIO280621.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TIO 28/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  010621.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  010621.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI  01/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  040621.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  040621.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI  04/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  110221.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  110221.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI  11/02/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  170521.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI  170521.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI  17/05/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 020321.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 020321.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI 02/03/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 080621.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 080621.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI 08/06/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 100521.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 100521.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI 10/05/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 120821.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 120821.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI 12/08/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 270121.png"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 270121.png" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI 27/01/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 100821.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/TOI 100821.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI 10/08/21</h4>
        </div>
      </div>
      <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
        <div className="column1"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Times-NIE.jpeg"> <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/media/Times-NIE.jpeg" alt="Air Force Golden Jubilee" className="hover-shadow img-responsive " /> </a></LazyLoad>
          <h4 className="media-head">TOI -14/06/2021 </h4>
        </div>
      </div>
    </div>
  </div>
                <Footer />
        </>
    )
}

export default Mediagallery