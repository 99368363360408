
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Ukgv = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Classes UKG to V" breadcrumbName="Classes UKG to V" />

            <div className="container body_height">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="subhead">Time Table</h3>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class-ukg.pdf" target="_blank">Class UKG</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class1AandB.pdf" target="_blank">Class 1st A and B</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class-1C nd 1D.pdf" target="_blank">Class 1st C and D</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/Class-2.pdf" target="_blank">Class 2nd</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class-3aand3b.pdf" target="_blank">Class 3rd A and B</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class-3cand 3d.pdf" target="_blank">Class 3rd C and D</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/Class-4.pdf" target="_blank">Class 4th</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class-V-AandB.pdf" target="_blank">Class 5th A and B</Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Time-Table/Primary/class-VCandVD.pdf" target="_blank">Class 5th C and D</Link> </div>
                    </div>

                </div>

                <div className="clr20"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="subhead">Syllabus</h3>
                    </div>

                    <p><strong>Syllabus for UT 2</strong> </p>
                    <div className="cl25"></div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-1-Syllabus-UT-2.pdf" target="_blank">Class 1st </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-2-Syllabus-UT-2.pdf" target="_blank">Class 2nd </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-3-Syllabus-UT-2.pdf" target="_blank">Class 3rd </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-4-Syllabus-UT-2.pdf" target="_blank">Class 4th </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-5-Syllabus-UT-2.pdf" target="_blank">Class 5th </Link> </div>
                    </div>
                    <div className="clr10"></div>
                    <p><strong>Syllabus for UT 1</strong> </p>
                    <div className="cl25"></div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-1-Syllabus-2020.pdf" target="_blank">Class 1st </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-2-Syllabus-2020.pdf" target="_blank">Class 2nd </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-3-Syllabus-2020.pdf" target="_blank">Class 3rd </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-4-Syllabus-2020.pdf" target="_blank">Class 4th </Link> </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-5-Syllabus-2020.pdf" target="_blank">Class 5th </Link> </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Ukgv