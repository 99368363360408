
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LazyLoad from 'react-lazyload';
import Breadcrumb from "../Component/Breadcrumb";
const Alumni = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Alumni" breadcrumbName="Alumni" />
      <div className="container body_height ">
        <div className="row alumnisec">
          <div className="col-md-12">
            <p><b>Luke Talwar, an alumni of AFGJI batch 2011</b></p>
            <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/alumni/Luke-Talwar.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad>
          </div>
          <div className="col-md-12">
            <p><b>VANSHIKA PARMAR (BATCH  2019-20)</b></p>
            <p>Vanshika Parmar, an Alumni of  AFGJI batch of 2019-20, who is presently pursuing her majors from Miranda House, University of has been crowned as <b>Miss Earth India-Beauty with Responsibility</b> 2021 at the age of 18.  The event was held at Welcom Hotel ITC Dwarka and was organised by Divine Group India-The official franshise holder of Miss Earth India. She has also been featured on the Billboard of Air force station Agra.</p>
          </div>
          <div className="col-md-3 col-sm-6"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/alumni/VANSHIKA-PARMAR-1.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad></div>
          <div className="col-md-3 col-sm-6"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/alumni/VANSHIKA-PARMAR-4.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad></div>
          <div className="col-md-3 col-sm-6"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/alumni/VANSHIKA-PARMAR-2.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad></div>
          <div className="col-md-3 col-sm-6"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/alumni/VANSHIKA-PARMAR-3.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad></div>
        </div>
        <div className="clr25"></div>
        <div className="row alumnisec">
          <div className="col-md-4"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Vikhyat-Tomar.jpg" className="img-responsive" alt="AFGJI" /></LazyLoad></div>
          <div className="col-md-8"><p className="text-center"><strong>Vikhyat Tomar </strong>an Alumni of AFGJI batch of 2016 has been got recommand for Indian Naval Academy for the course GS X NOC 35.</p></div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Alumni
