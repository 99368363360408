
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
const Memories = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Memories" breadcrumbName="Memories" />
            
            <div className="container body_height">
                <div className="row"> <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/cm.png" className="img-responsive center-block" alt="AFGJI" /> </LazyLoad></div>
            </div>
            <Footer />
        </>
    )
}

export default Memories