import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';

const Library = () => {
  // React state to track the active tab
  const [activeTab, setActiveTab] = useState('lsw1');

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Library Senior Wing" breadcrumbName="Library Senior Wing" />

      <div className="container body_height">
        <div className="row">
          <div className="col-md-3 leftmenu">
            <div className="leftmenubg">
              <p className="menuhead">Important Links</p>
            </div>
            <div id="events">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className={activeTab === 'lsw1' ? 'active' : ''}>
                  <Link to="#lsw1" onClick={() => setActiveTab('lsw1')}>Introduction</Link>
                </li>
                <li role="presentation" className={activeTab === 'lsw2' ? 'active' : ''}>
                  <Link to="#lsw2" onClick={() => setActiveTab('lsw2')}>Timing</Link>
                </li>
                <li role="presentation" className={activeTab === 'lsw3' ? 'active' : ''}>
                  <Link to="#lsw3" onClick={() => setActiveTab('lsw3')}>Rules</Link>
                </li>
                <li role="presentation" className={activeTab === 'lsw4' ? 'active' : ''}>
                  <Link to="#lsw4" onClick={() => setActiveTab('lsw4')}>Services</Link>
                </li>
                <li role="presentation" className={activeTab === 'lsw5' ? 'active' : ''}>
                  <Link to="#lsw5" onClick={() => setActiveTab('lsw5')}>Collection</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-9">
            <div className="tab-content">
              {/* Introduction Tab */}
              {activeTab === 'lsw1' && (
                <div role="tabpanel" className="tab-pane active" id="lsw1">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="sub_head">Introduction</h3>
                      <p>AFGJI can boast of a computerised library facility which caters to the need of the staff and students. It is here that children are lost in a world of imagination. </p>
                      <div className="pad-10"></div>
                      There is a rich collection of more than 20000 books in all subjects with a very rich reference collection to help our students to work on multi-dimensional projects and to prepare for various examinations. This collections also satisfies their urge to read more books for pleasure and for knowledge. A good number of magazines and periodicals are also available. Junior fiction is shelved separately in the library.
                      <p></p>
                      <div className="pad-10"></div>
                      <p><strong>There is a separate library for junior and senior school students.</strong></p>
                    </div> </div>
                  <div className="pad-10"></div>
                  <div className="pad-10"></div>
                  <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0024.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0025.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0026.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                  </div>
                  <div className="pad-10"></div>
                  <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0028.jpg" className="img-responsive center-block " alt="AFGJI" /></LazyLoad></div>
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0029.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0030.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                  </div>
                  <div className="pad-10"></div>
                  <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0034.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                    <div className="col-xs-4 col-sm-4 col-md-4 width-100"><LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Library-SeniorWings/DSC_0035.jpg" className="img-responsive center-block" alt="AFGJI" /></LazyLoad></div>
                  </div>
                </div>
              )}

              {/* Timing Tab */}
              {activeTab === 'lsw2' && (
                <div role="tabpanel" className="tab-pane active" id="lsw2">
                  <h3 className="sub_head">Timing</h3>
                  <p><strong><strong>The library remains open on all school day from 8 a.m. to 2 p.m.</strong></strong> </p>
                  <div className="pad-20"></div>
                  <strong>Library Periods</strong>
                  <div className="pad-10"></div>
                  All students have one library period per week as per school time table. Students can take &amp; return book during their library period. Further students can return library books any time during the library working hours.
                  <div className="pad-20"></div>
                  <strong>Borrowing information</strong>
                  <div className="pad-10"></div>
                  <strong>Students</strong> One book for a period of two weeks with three renewals. Students should provide their admission number for the automated circulation of books.
                  <div className="pad-20"></div>
                  <strong>Faculty and staff</strong>
                  <div className="pad-10"></div>
                  Maximum five books can be issued for a period of one month with three renewals. Users should provide their ID number for the automated circulation of books.
                  <p></p>
                </div>
              )}

              {/* Rules Tab */}
              {activeTab === 'lsw3' && (
                <div role="tabpanel" className="tab-pane active" id="lsw3">
                  <h3 className="sub_head">Library Rules</h3>
                  <p><strong>1.</strong> Strict silence is to be maintained in the library.<br />
                    <strong>2.</strong> All students from classes 6th to 12th and all staff members are entitled to become members of the library.<br />
                    <strong>3. </strong>No person shall write upon, tear, cut, deface, damage or make any mark upon anybook belonging to the library.<br />
                    <strong>4.</strong> Readers shall be responsible to make good the loss, the damage done to the books or
                    other property belonging to the library either by replacing such books with new ones or other
                    property damaged or by paying the value thereof with 10% additional charges as departmental
                    expenses. In case of damage / loss of a volume of a set, the whole set will have to be
                    replaced.<br />
                    <strong>5.</strong> Each student is entitled to take out one book at a time for a period of 14
                    days, while staff members are entitled to take Five books at a time and keep the
                    same for 30 days. Maximum 3 renewals are permitted for one book.<br />
                    <strong>6.</strong> Dictionaries and other costly reference books are not be lent out,
                    however, periodical publications will be issued to staff members on week end
                    basis.<br />
                    <strong>7.</strong> In case the books are not returned on due date, a fine of Rs. 1/- will be charged per
                    book on each day and no new book shall be issued thereafter, unless the member returns the
                    books with fine.<br />
                    <strong>8.</strong> All reference books taken by the reader for consultation in the library should be returned
                    to the Librarian before leaving the library.<br />
                    <strong>9.</strong> If the membership card is lost by a member, he/she should give a written report to the
                    librarian. A duplicate member ship card will be issued to the member on payment of Rs. 5/-<br />
                    <strong>10.</strong> Library books are taken by the members on their own responsibility. Nobody is allowed
                    to sub-lend the books issued to them.<br />
                    <strong>11.</strong> School leaving / transfer certificates shall not be given to students until they have
                    returned all the books along with Library cards and has paid the dues outstanding against their
                    names.<br />
                    <strong>12.</strong> Subject books &amp; other books which are in great demand will be issued for one
                    week only.<br />
                    <strong>13.</strong> Issue of books may be suspended at the time of stock-taking.<br />
                    <strong>14.</strong> Eatables, drinks, Bags, personal books and such other articles are not permitted to be
                    brought inside the Library. These are to be left outside the library.<br />
                    <strong>15.</strong> At the end of each academic year students of classes X &amp; XII should return their
                    library cards to the Librarian.<br />
                    <strong>16.</strong> Staff members are to return library cards on retirement/resignation.</p>
                </div>
              )}

              {/* Services Tab */}
              {activeTab === 'lsw4' && (
                <div role="tabpanel" className="tab-pane active" id="lsw4">
                  <h3 className="sub_head">Services</h3>
                  <p><strong>Library Automation</strong> </p>
                  <div className="pad-10"></div>
                  The stock and services of the library has been automated by using Libsys Library Software. The library follows an open access system i.e, Online Public Access Catalogue (OPAC). Users can search any book by using keywords like author, title and subject, call number, etc. The books are classified and shelved according to the Dewey Decimal Classification. Every book is barcoded and spine labels are pasted in it. The other services include :-
                  <div className="pad-10"></div>
                  <span className="black-bold">Circulation of books(issue and return) </span>
                  <div className="pad-10"></div>
                  Library period for all students<br />
                  OPAC(Online Public Access Catalogue)<br />
                  Weekend/Overnight issue of periodicals<br />
                  Reference services<br />
                  Exhibitions/Displays of books<br />
                  SDI(Selective Dissemination of Information)<br />
                  New Book Alerts<br />
                  Various entrance test Question papers<br />
                  Newspaper clipping services<br />
                  Reservation of books<br />
                  Question bank
                  <p></p>
                </div>
              )}

              {/* Collection Tab */}
              {activeTab === 'lsw5' && (
                <div role="tabpanel" className="tab-pane active" id="lsw5">
                  <h3 className="sub_head">Collection</h3>
                  <h4 className="main-head">Books</h4>
                  <p><b>Total number of books as per Accession Register (31 Mar 2017) :   20939</b><br />
                    Newly added books in this session (Till Aug 2017) :   600<br />
                    <b>Reference Book   :   1789</b><br />
                    Gifted Books   :   1145<br />
                    <b>Bounded periodicals  :    249</b><br />
                    No. of books withdrawn (upto 31 Mar 2017):   4277 </p>
                  <div className="pad-10"></div>
                  <p><b>CD/DVDs</b> Educational CDs/DVDs :  101</p>
                  <div className="pad-10"></div>
                  <h4 className="main-head">News Paper</h4>
                  <p> The Times of India<br />
                    Indian Express<br />
                    The Hindustan Times<br />
                    Navbharat Times<br />
                    The Hindu</p>
                  <div className="pad-10"></div>
                  <div className="col-md-12">
                    <div className="tablescroll">
                      <h3 className="main-head">Magazines ( Under Subscription.)</h3>
                      <table className="table table-bordered faculty">
                        <thead>
                          <tr>
                            <th>Sl.no</th>
                            <th>Name of Magazine</th>
                            <th>Sl.No</th>
                            <th>Name of magazine</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> 1</td>
                            <td> Art India </td>
                            <td> 2</td>
                            <td> Business India </td>
                          </tr>
                          <tr>
                            <td> 3</td>
                            <td> Better Photography</td>
                            <td> 4</td>
                            <td> Biology Today </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td> Chemistry Today </td>
                            <td> 6</td>
                            <td>Competition Success Review</td>
                          </tr>
                          <tr>
                            <td> 7</td>
                            <td> Champak </td>
                            <td> 8</td>
                            <td>Children’s World</td>
                          </tr>
                          <tr>
                            <td> 9</td>
                            <td> Career 360 </td>
                            <td> 10</td>
                            <td>Down to Earth</td>
                          </tr>
                          <tr>
                            <td> 11</td>
                            <td> Digit</td>
                            <td> 12</td>
                            <td>Economics & Political Weekly</td>
                          </tr>
                          <tr>
                            <td> 13</td>
                            <td> Employment News</td>
                            <td> 14</td>
                            <td>Education World</td>
                          </tr>
                          <tr>
                            <td> 15</td>
                            <td> Frontline</td>
                            <td> 16</td>
                            <td>Femina </td>
                          </tr>
                          <tr>
                            <td> 17</td>
                            <td> India Today (Hindi)</td>
                            <td> 18</td>
                            <td>India Today (English) </td>
                          </tr>
                          <tr>
                            <td> 19</td>
                            <td> Jr. Science Refresher</td>
                            <td> 20</td>
                            <td>Main Stream </td>
                          </tr>
                          <tr>
                            <td> 21</td>
                            <td> Mathematics Today</td>
                            <td> 22</td>
                            <td>National Geography </td>
                          </tr>
                          <tr>
                            <td> 23</td>
                            <td> Physics for you</td>
                            <td> 24</td>
                            <td>Readers Digest </td>
                          </tr>
                          <tr>
                            <td> 25</td>
                            <td> Sports Star</td>
                            <td> 26</td>
                            <td>Science Reporter</td>
                          </tr>
                          <tr>
                            <td> 27</td>
                            <td> Terra Green</td>
                            <td> 28</td>
                            <td>Tell me why</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="tablescroll">
                      <h3 className="main-head">Magazine (Gift)</h3>
                      <table className="table table-bordered faculty">
                        <thead>
                          <tr>
                            <th>Sl.no</th>
                            <th>Name of Magazine</th>
                            <th>Sl.No</th>
                            <th>Name of magazine</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> 1</td>
                            <td> Air Force News </td>
                            <td> 2</td>
                            <td> Akshay Urja </td>
                          </tr>
                          <tr>
                            <td> 3</td>
                            <td> Brain feed</td>
                            <td> 4</td>
                            <td> BBC Knowledge</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td> Civil Society </td>
                            <td> 6</td>
                            <td> Dream 2047</td>
                          </tr>
                          <tr>
                            <td> 7</td>
                            <td> Digital learning </td>
                            <td> 8</td>
                            <td>Education News</td>
                          </tr>
                          <tr>
                            <td> 9</td>
                            <td> India Perspectives </td>
                            <td> 10</td>
                            <td> Kens Connect</td>
                          </tr>
                          <tr>
                            <td> 11</td>
                            <td> Mentor</td>
                            <td> 12</td>
                            <td>Nuclear India</td>
                          </tr>
                          <tr>
                            <td> 13</td>
                            <td> Span </td>
                            <td> 14</td>
                            <td> Shikshak Sathi</td>
                          </tr>
                          <tr>
                            <td> 15</td>
                            <td> Teacher</td>
                            <td> 16</td>
                            <td>Vipnet News </td>
                          </tr>
                          <tr>
                            <td> 17</td>
                            <td> Young Intach</td>
                            <td> 18</td>
                            <td> - </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Library;
