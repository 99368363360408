import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Highlights from '../Component/Highlights';
import LazyLoad from 'react-lazyload';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Highlights />
            <div className='special-admission'>
                <Link to="/specialWing">Admission Open (Special Wing - Special Education School)</Link>
            </div>
            <div className="container-fluid bgimage">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <Link to="/">
                                <LazyLoad>  <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/logo.png" className="img-responsive center-block" alt="AFGJI" /></LazyLoad>
                            </Link>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <ul className="toplink">
                                        <li><NavLink to="/tender">Tender</NavLink></li>
                                        <li><NavLink to="/alumni">Alumni</NavLink></li>
                                        <li><NavLink to="/tc_issued">TC Issued</NavLink></li>
                                        <li><NavLink to="/recruitment">Recruitment</NavLink></li>
                                        <li><NavLink to="https://www.facebook.com/" target="_blank"><i className="bi bi-facebook"></i></NavLink></li>
                                        <li><NavLink to="https://twitter.com/" target="_blank"><i className="bi bi-twitter-x"></i></NavLink></li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <ul className="toplink special-wing">
                                        <li className="blinker">Special Wing:</li>
                                        <li><NavLink to="https://www.youtube.com/@afgji-specialwingsubrotopa1374" target="_blank">
                                            <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/youtube.png" /></NavLink></li>
                                        <li><NavLink to="https://www.facebook.com/people/SpecialWing-Afgji/pfbid0GAc9y4fBUjncM4NqYMXne3aXkR84XwsCA8oDPhtnD4LN1qntXzLfj1jvpspjoRDul/?mibextid=ZbWKwL" target="_blank">
                                            <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/facebook.png" /></NavLink></li>
                                        <li><NavLink to="https://www.instagram.com/specialwing_afgji/?igsh=YzljYTk1ODg3Zg%3D%3D" target="_blank">
                                            <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/instagram.png" />
                                        </NavLink></li>
                                        <li><NavLink to="https://x.com/swafgji" target="_blank">
                                            <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/twitter.png" /></NavLink></li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 address">
                                    <span><i className="bi bi-telephone-fill"></i><NavLink to="tel:011-25692819">+011-25692819</NavLink> </span>
                                    <span><i className="bi bi-envelope-fill"></i><NavLink to="mailto:afgjisp@gmail.com">afgjisp@gmail.com</NavLink></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid menu_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 menu_bg">
                            <div className="navbar-header">
                                <button
                                    type="button"
                                    className={`navbar-toggle ${isOpen ? 'collapsed' : ''}`}
                                    onClick={toggleNavbar}
                                >
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            <div className={`navbar-collapse collapse ${isOpen ? 'in' : ''}`}>
                                <ul className="nav navbar-nav">
                                    <li><NavLink to="/" id="home"><i class="bi bi-house-door-fill"></i></NavLink></li>
                                    <li className="dropdown">
                                        <NavLink className="dropdown-toggle" data-toggle="dropdown" id="about">About Us</NavLink>
                                        <ul className="dropdown-menu animated flipInX">
                                            <li><NavLink to="/ourschool" id="ourschool">Our School</NavLink></li>
                                            <li><NavLink to="/ourhistory" id="History">Our History</NavLink></li>
                                            <li><NavLink to="/management" id="Management">Management</NavLink></li>
                                            <li><NavLink to="/vision_mission" id="Vision">Vision &amp; Mission</NavLink></li>
                                            <li><NavLink to="/message_desk" id="message_desk">Message Desk</NavLink></li>
                                            <li><NavLink to="/mandatory_Information" id="mandatory_Information">Mandatory Information</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink to="/specialWing" id="specialWing">Special Wing</NavLink></li>
                                    <li className="dropdown"> <NavLink className="dropdown-toggle" data-toggle="dropdown" id="academic">Academic </NavLink>
                                        <ul className="dropdown-menu animated flipInX">
                                            <li><NavLink to="/rules_regulation" id="rules_regulation">Rules &amp; Regulation</NavLink></li>
                                            <li><NavLink to="/uniform_timing" id="uniform_timing">Uniform &amp; Timing</NavLink></li>
                                            <li><NavLink to="/school_calender" id="calender">School Calender</NavLink></li>
                                            <li><NavLink to="/syllabus" id="syllabus">Syllabus</NavLink></li>
                                            <li><NavLink to="/datesheet" id="datesheet">Datesheet</NavLink></li>
                                            <li><NavLink to="/time_table" id="time_table">Time Table</NavLink></li>
                                            <li><NavLink to="/QuestionBank" id="question_bank">Question Bank</NavLink></li>
                                            <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Booklist-Primary-Wing.pdf" target="_blank">Book List of Primary Wing</NavLink></li>
                                            <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Booklist-Senior-Wing.pdf" target="_blank">Book List of Senior Wing</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"> <NavLink className="dropdown-toggle" data-toggle="dropdown" id="admission">Admission </NavLink>
                                        <ul className="dropdown-menu animated flipInX">
                                            {/* <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Classxii-Admission.pdf" target="_blank">  Notice For Class XI Admission</NavLink></li>
               <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-Seventh-List-for-Admission-2024-25.pdf" target="_blank">  AF Category Seventh  List for Admission 2024-25</NavLink></li> 
               <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-sixth-List-for-Admission-2024-25.pdf" target="_blank">  AF Category Sixth List for Admission 2024-25</NavLink></li> 
               <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-fifth-List-for-Admission-2024-25.pdf" target="_blank">  AF Category Fifth List for Admission 2024-25</NavLink></li> 
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-fourth-List-for-Admission-2024-25.pdf" target="_blank"> AF Admission Fourth List for Admission 2024-25  </NavLink></li> 
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Provisional-Stream-Allocation-ClassXI.pdf" target="_blank"> Provisional Stream Allocation Class XI 2024-25</NavLink></li> 
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-third-List-for-Admission-2024-25.pdf" target="_blank"> AF Category Third List for admission 2024-25  </NavLink></li> 
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-Second-List-for-Admission-2024-25.pdf" target="_blank"> AF Category Second List for admission 2024-25  </NavLink></li>  
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/AF-Category-First-List-for-Admission-2024-25.pdf" target="_blank"> AF Category First List For Admission 2024-25  </NavLink></li>   
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-Third-List-along-with-waiting-List-2024-24.pdf" target="_blank">UKG NAF Third List along with waiting List 2024-24</NavLink></li>
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-SECOND-LIST-ALONG-WITH-WAITING-LIST-2024-25.pdf" target="_blank">UKG NAF Second List along with waiting List 2024-24</NavLink></li>      
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Corrigendum-UKG-NAF-Admission-2024-25.pdf" target="_blank">Corrigendum UKG NAF Admission 2024-25 </NavLink></li> */}
<li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-FIRST-LIST-ALONG-WITH-WAITING-LIST-2025-26.pdf" target="_blank">UKG NAF FIRST LIST ALONG WITH WAITING LIST 2025-26</NavLink></li>
<li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/LIST-CHILDREN-WHO-APPLIED-ADMISSION-CLASS-UKG-NAF-CATEGORY-POINTS-2025-26.pdf" target="_blank">LIST OF CHILDREN WHO APPLIED FOR ADMISSION IN CLASS UKG NAF CATEGORY WITH POINTS 2025-26</NavLink></li>
<li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/list-applied-admission-for-2025-26.pdf" target="_blank">LIST OF CHILDREN WHO APPLIED FOR ADMISSION IN CLASS UKG NAF CATEGORY 2025-26</NavLink></li>
                                            <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/admission-notice-for-UKG-XII.pdf" target="_blank">Admission Notice For Wards Of Serving Air Force Personnal From UKG To XII</NavLink></li>
                                            <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-ADMISSION.pdf" target="_blank">Notification Of UKG NAF Admission(2025-26) </NavLink></li>
                                            <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Fee_Structure.pdf" target="_blank">Fee Structure: General Wing for the year 2024-25 </NavLink></li>
                                            <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Admission-criteria-SpecialWing.pdf" target="_blank">Admission  Criteria &amp; Admission Process (Special Wing) </NavLink></li>
                                            {/*               
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-admission-2024-25.pdf" target="_blank">UKG  NAF Admission Schedule 2024-25</NavLink></li>
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/List-UKG-NAF-CATEGORY-2024-25.pdf" target="_blank">List of Children Who have applied for Admission in UKG NAF Category 2024-25</NavLink></li>
              <li><NavLink to="https://webapi.entab.info/api/image/AFGJI/public/pdf/admission-UKG-24-25.pdf" target="_blank">List of Children Who Applied for Admission in Class UKG NAF Category With Points 2024-25</NavLink></li> */}


                                        </ul>
                                    </li>
                                    <li className="dropdown"> <NavLink className="dropdown-toggle" data-toggle="dropdown" id="infrastructure">Infrastructure</NavLink>
                                        <ul className="dropdown-menu animated flipInX">
                                            <li><NavLink to="/infrastructure_special_wing" id="infrastructure_special_wing">Special Wing (Special School) </NavLink></li>
                                            <li><NavLink to="/infrastructure_primary_wing" id="infrastructure_primary_wing">Primary Wing</NavLink></li>
                                            <li><NavLink to="/infrastructure_senior_wing" id="infrastructure_senior_wing">Senior Wing </NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"> <NavLink className="dropdown-toggle" data-toggle="dropdown" id="activities">Activities</NavLink>
                                        <ul className="dropdown-menu animated flipInX">
                                            <li><NavLink to="/activities-special-wing" id="activities-special-wing">Special Wing (Special School) </NavLink></li>
                                            <li><NavLink to="/activities-primary-wing" id="activities-primary-wing">Primary Wing</NavLink></li>
                                            <li><NavLink to="/activities-senior-wing" id="activities-senior-wing">Senior Wing </NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"> <NavLink className="dropdown-toggle" data-toggle="dropdown" id="gallery">Gallery</NavLink>
                                        <ul className="dropdown-menu animated flipInX">
                                            <li><NavLink to="/Gallery" id="photogallery">Photo Gallery</NavLink></li>
                                            <li><NavLink to="/video_gallery" id="video_gallery">Video Gallery</NavLink></li>
                                            <li><NavLink to="/media_gallery" id="media_gallery">Media Gallery</NavLink></li>
                                            <li><NavLink to="/virtual_tour" id="virtual_tour">Virtual Tour</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink to="/CBSE-circular" id="CBSE-Circular">CBSE Circular</NavLink></li>
                                    <li className="dropdown"><NavLink id="Magazine" className="dropdown-toggle" data-toggle="dropdown">Magazine</NavLink>
                                    <ul className="dropdown-menu animated flipInX">
                <li><NavLink to="/Swarnima" id="Magazine">Swarnima</NavLink></li>
                <li><NavLink to="/milestone" id="Magazine">Milestone</NavLink></li>
                {/* <li><NavLink to="/Magazine" id="Magazine">Other</NavLink></li>  */}
              </ul>
                                    </li>
                                    <li><NavLink to="/contact_us" id="contact_us">Contact Us</NavLink></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;
